// sorted
$mdc-theme-primary: #0d47a1; // #3B63A0
$mdc-theme-on-primary: #ffffff;

$mdc-theme-secondary: #78909c; // #b3dbef
$mdc-theme-on-secondary: #000000;

// not sorted
$mdc-theme-surface: #ffffff;
$mdc-theme-on-surface: #002171;

$mdc-theme-background: #a7c0cd;
$mdc-theme-on-background: #4b636e;



//// $mdc-theme--primary 	//Sets the text color to the theme primary color.
//// $mdc-theme--primary-bg 	Sets the background color to the theme primary color.
// $mdc-theme--on-primary 	Sets the text color to the color configured for text on the primary color.
//// $mdc-theme--secondary 	Sets the text color to the theme secondary color.
//// $mdc-theme--secondary-bg 	Sets the background color to the theme secondary color.
// $mdc-theme--on-secondary 	Sets the text color to the color configured for text on the secondary color.
// $mdc-theme--surface 	Sets the background color to the surface background color.
// $mdc-theme--on-surface 	Sets the text color to the color configured for text on the surface color.
// $mdc-theme--background 	Sets the background color to the theme background color.

// <color name="primaryColor">#0d47a1</color>
///<color name="primaryLightColor">#5472d3</color>
///<color name="primaryDarkColor">#002171</color>
//<color name="secondaryColor">#78909c</color>
///<color name="secondaryLightColor">#a7c0cd</color>
///<color name="secondaryDarkColor">#4b636e</color>
//<color name="primaryTextColor">#ffffff</color>
//<color name="secondaryTextColor">#000000</color>
