.newButton {
  height: 30px;
  font-size: 12px !important;
  font-weight: 550 !important;
  margin-bottom: 8px !important;
}

.newButton:hover {
  background-color: #eaf2ff !important;
  color: #134a9f !important;
  border: 1px solid #ccdcf6 !important;
}

.fullWidth {
  width: 100%;
}

.hover-button:hover {
  box-shadow: 0px 2px 6px #ccdcf6;
  cursor: pointer;
  transform: scale(1.03);
}

.filter-button:hover {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.54);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  transform: scale(1.01);
}

.reassign-button {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  color: #134a9f;
  display: flex;
}

.reassign-button:hover {
  background-color: white;
  border: 1px solid #134a9f;
  border-radius: 6px;
  color: #134a9f;
  display: flex;
}

.new-process {
  align-items: flex-start;
  border: 2px solid #eff0f3;
  border-radius: 4px;
  display: flex;
  padding: 8px 16px !important;
  width: 100%;
}

.new-process:hover {
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  background-color: #eaf2ff;
  transform: scale(1.009);
}

.select-head-cell {
  background-color: #eff0f3;
  border: "none";
}
