.logo {
  height: 40px;
}

@import "@material/top-app-bar/mdc-top-app-bar";

.jheader {
  @include mdc-top-app-bar-ink-color(#0d47a1);
  @include mdc-top-app-bar-icon-ink-color(#0d47a1);
  @include mdc-top-app-bar-fill-color(#ffffff);
  @include mdc-top-app-bar-fill-color-accessible(#ffffff);
}

.mdc-top-app-bar__row {
  display: flex;
  flex-wrap: nowrap;
  height: 74px;
}

.dashboardTitle {
  font-family: inter !important;
  color: #2f4256;
}
@media (max-width: 600px) {
  .dashboardTitle {
    font-size: 20px !important;
    padding-left: 0px !important;
  }
  .mdc-top-app-bar__row {
    overflow-x: hidden;
    white-space: nowrap;
  }
  .hamburgerIcon {
    width: 30px;
    height: 30px;
  }
}

.mdc-top-app-bar__section {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 20px 24px 20px 12px;
}

@media (max-width: 500px) {
  .jheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .mdc-top-app-bar__row {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .mdc-top-app-bar__section {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }

  .mdc-top-app-bar__section--align-start {
    flex-grow: 1;
    min-width: 0;
  }

  .mdc-top-app-bar__section--align-end {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 350px) {
  .mdc-top-app-bar__section {
    padding: 24px 24px 24px 0px;
  }
  .hamburger {
    padding-left: 10px;
  }
}
