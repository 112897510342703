@import "@material/layout-grid/mdc-layout-grid";

@media (min-width: 840px) {
  .login-grid {
    @include mdc-layout-grid(desktop, 0px, 40%);
  }
}

@media (max-width: 839px) and (min-width: 480px) {
  .login-grid {
    @include mdc-layout-grid(desktop, 0px, 40%);
  }
}

@media (max-width: 479px) {
  .login-grid {
    @include mdc-layout-grid(phone, 0px, 90%);    
  }
}

.fieldWidth {
  width: 20vw;
  min-width: 200px !important;
}