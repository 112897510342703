.logo-loader {
  height: 100px;
}

// include some standard spacings
@import "./Spacing.scss";

// import custom theme
@import "./Colors.scss";

@import "@material/react-button/index.scss";
@import "@material/react-layout-grid/index.scss";
@import "@material/react-typography/index.scss";
@import "@material/react-text-field/index.scss";
@import "@material/react-material-icon/index.scss";
@import "@material/react-icon-button/index.scss";
@import "@material/react-card/index.scss";
@import "@material/react-list/index.scss";
@import "@material/react-dialog/index.scss";
@import "@material/react-linear-progress/index.scss";
@import "@material/react-checkbox/index.scss";
@import "@material/react-select/index.scss";
@import "@material/react-radio/index.scss";
@import "@material/react-drawer/index.scss";

// Adjust the header margins
$h1-margin: 0.4em;

@for $i from 1 through 6 {
  .mdc-typography--headline#{$i} {
    margin-top: $h1-margin;
    margin-bottom: $h1-margin;
  }
}

.mdc-drawer-app-content {
  background-color: #f7f8fc;
}
// drawer stuff
.drawer-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  background-color: "#F7F8FC";
}

.drawer-app-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
}

// some utilities
.pointer {
  cursor: pointer;
  padding: 0px, 16px, 0px, 4px;
  width: 200px;
}

.break-word {
  word-break: break-word;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.alternate {
  color: $mdc-theme-primary;
}

.border-bottom-line {
  border-bottom: 1px $mdc-theme-primary solid;
}

// positioning
.float-right {
  float: right;
}

.form-group-margin .field-string {
  margin: 20px;
}

.no-left-margin .field-string {
  margin: 20px 20px 20px 0;
}

.hidden {
  display: none !important;
}
.desktopHeader {
  z-index: 1;
  box-shadow: 2px 4px 12px 0px rgba(47, 66, 86, 0.1);
}
.mdc-list a {
  text-decoration: none;
}

.active .mdc-list-item {
  background-color: #134a9f !important;
}
.active .mdc-list-item__text {
  color: #fbfbfb;
}

.active .icon {
  fill: #fbfbfb !important;
}
.mdc-drawer .mdc-list-item {
  margin-left: 16px;
  font-family: Inter, Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit;
  height: calc(48px - 2 * 4px);
  padding: 0 8px;
}

::-webkit-scrollbar {
  width: 6px !important;
  color: #999ca3;
}
::-webkit-scrollbar-track {
  background: transparent !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 20px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.dashboardPaper {
  &.MuiPaper-rounded {
    box-shadow: none !important;
  }
}

@mixin button {
  box-shadow: none !important;
  font-size: 18px !important;
  font-variant: all-small-caps;
  font-weight: 600 !important;
  height: 36px;
  letter-spacing: 0.02em !important;
  line-height: 19px !important;
  padding: 4px 16px 6px !important;
}

.cancel-button {
  @include button;
  background-color: white !important;
  border: 2px solid #db3069 !important;
  color: #db3069 !important;
  font-size: 16px !important;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 22.4px;
}

.cancel-button:hover {
  background-color: #fadce6 !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25) !important;
  transform: scale(1.006);
}

.confirm-button {
  @include button;
  background-color: #134a9f !important;
  color: white !important;
  font-size: 16px !important;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 22.4px;
}

.confirm-button:hover {
  background-color: #103d82 !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25) !important;
  transform: scale(1.006);
}

.disable-button {
  @include button;
  background-color: #134a9f !important;
  color: white !important;
  opacity: 0.38;
}

.objectDataTableContainer .MuiPaper-root {
  background-color: #f7f8fc;
  padding: 24px;
  flex-grow: 1;
}
.objectDataTableContainer .MuiPaper-elevation4 {
  box-shadow: none !important;
}

.dashboardDataTableContainer .MuiPaper-root {
  background-color: #f7f8fc;
}
.dashboardDataTableContainer .MuiPaper-elevation4 {
  box-shadow: none !important;
}

.icon-container:hover .menu-path {
  fill: rgba(0, 0, 0, 0.04);
}

.multiBoxTable {
  margin-bottom: 30px;
}
.multiBoxTable th {
  background-color: #eaf2ff;
  font-weight: bold;
  color: #000000;
}

.multiBoxTableCell {
  padding: 0px !important;
}

.MuiTableCell-head {
  border-right: 1px solid rgba(185, 185, 185, 0.5);
}

.newProcess-button {
  @include button;
  white-space: nowrap;
  background-color: #db3069 !important;
  color: white !important;
  font-family: "Roboto";
  padding: 8px 16px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25.2px !important;
  box-shadow: none;
  border: none;
  border-radius: 4px !important;
  height: 46px !important;
  cursor: pointer;
}

.filterDialog {
  display: flex;
  flex-direction: column;
  min-height: 470px;
  justify-content: space-between;
}

.notificationButton {
  width: 46px !important;
  height: 46px !important;
}

.buttonScrollContainer {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
  display: flex;
  padding-top: 16px;
}
@media (max-width: 1030px) {
  .newProcess-button {
    font-size: 18px !important;
  }
}

@media (max-width: 1024px) {
  .filterDialog {
    min-height: 40vh;
  }
  .filterContent {
    max-height: 140px;
    overflow-y: scroll;
  }
}

@media (max-width: 715px) {
  .newProcess-button {
    font-size: 16px !important;
  }
}

@media (max-width: 665px) {
  .MuiToolbar-regular {
    min-height: 40px !important;
  }
  .MuiToolbar-gutters {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .dashboardtab {
    display: inline-block !important;
    white-space: nowrap;
    overflow-x: scroll;
  }
  #tabsButton {
    margin-right: 8px;
  }
  .dashboardtab::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
  .quickFilters {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .quickFilters::-webkit-scrollbar {
    display: none;
  }
  .MUIDataTable::-webkit-scrollbar {
    display: none;
  }
  .MUIDataTableToolbar:root {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }
}

@media (max-height: 600px) {
  .filterDialog {
    min-height: 15vh;
  }
  .filterContent {
    max-height: 30px;
    overflow-y: scroll;
  }
}
@media (max-width: 600px) {
  .notificationInfo {
    font-size: 14px !important;
  }
}

@media (max-width: 475px) {
  .filterDialog {
    min-height: 30vh;
  }
  .filterContent {
    max-height: 70px;
    overflow-y: scroll;
  }
}

@media (max-width: 400px) {
  .reportPaper {
    height: auto !important;
  }
}

@media (max-width: 375px) {
  .newProcess-button {
    font-size: 18px !important;
  }
}
@media (max-width: 350px) {
  .notificationButton {
    margin-right: 0px !important;
  }
}
.selectLanguage {
  &.MuiInput-underline:before {
    border-bottom: none !important;
  }
}
