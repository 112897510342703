$margin: -1em !important;

.flex {
  margin-bottom: 8px;
}

.display {
  display: flex;
}

.errorMessage {
  color: red !important;
  margin-bottom: $margin;
}

.successMessage {
  color: #4caf50 !important;
  margin-bottom: $margin;
}

.field {
  width: 100% !important;
}

.error-message-field-drawer-close {
  max-width: 100vw !important;
  width: 100%;
}

.error-message-field-drawer-open {
  width: 100vw;
}

.form {
  width: 100% !important;
}

.gridWidth {
  width: 90%;
}

.padding-emptyRadio {
  padding: 20px 0;
}

.background-color {
  background-color: white;
}

.webhookButton,
.webhookButton::before {
  border: 2px solid #134a9f;
  padding: 8px 16px;
  color: #134a9f;
  letter-spacing: 0.0200012em !important;
  background-color: transparent;
  height: 44px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin-top: 6px;
}

.documentButton,
.documentButton::before {
  border: 2px solid #134a9f !important;
  padding: 8px 16px;
  color: #134a9f !important;
  height: 44px;
  letter-spacing: 0.0200012em !important;
  text-transform: capitalize !important;
  white-space: nowrap;
  display: block;
  margin-top: 6px !important;
}

.documentButton:hover {
  background-color: #eaf2ff !important;
}

.documentLabel {
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: unset;
}

.MuiArrowCircular {
  animation: MuiArrowSpinner-keyframes-circular-rotate 1.4s ease-in-out infinite;
}

.multiBox {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
}

.multiBox:hover {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
}

.multiBoxIcon {
  position: absolute;
  bottom: -27px;
  background: #eaf2ff;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
}

.multiBoxIcon:hover {
  border: 1px solid #ccdcf6;
}

@keyframes MuiArrowSpinner-keyframes-circular-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.outcomeButton {
  box-shadow: none !important;
  background-color: #134a9f !important;
}

.outcomeButton:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3) !important;
  background: #103d82;
}

.autoFillButton:hover {
  background-color: #eaf2ff !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25) !important;
}

.formulaText {
  font-weight: normal !important;
}
.downloadButton {
  box-shadow: none !important;
  border: 2px solid #134a9f !important;
  height: 44px;
  white-space: normal;
  text-overflow: ellipsis !important;
  display: block;
  overflow: hidden;
  text-transform: none !important;
  letter-spacing: 0.0200012em !important;
  padding: 8px 16px;
  margin-top: 6px !important;
  color: #134a9f !important;
}
.formulaSuccessMessage {
  color: #4caf50 !important;
  margin: 2px 8px !important;
  line-height: 80%;
  font-size: small;
}
.downloadButton:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #eaf2ff !important;
}

.downloadButtonLabel {
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.contentCopyIcon:hover {
  background-color: #eaf2ff;
  fill: #eaf2ff !important;
}

.MuiSlider-root {
  width: 967% !important;
}
.MuiSlider-thumb {
  width: 17px !important;
  height: 17px !important;
}
.MuiSlider-rail {
  height: 8px !important;
  border-radius: 4px !important;
}
.MuiSlider-track {
  height: 8px !important;
}

.sutContainer {
  padding: 24px;
}

.editorPrimaryButton:hover {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  background: #103d82;
}

.editorSecondaryButton:hover {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  background: #fadce6;
}
#rotateCircle:hover {
  fill: #eaf2ff;
}

.formLoader {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100vh !important;
}
