.Toastify__toast--success {
  background-color: #FFFFFF !important;
  color: #4caf50;
  border: 2px solid #4caf50;
  border-radius: 4px !important;
  font-size: 15px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 500;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25) !important; }

.Toastify__close-button {
  color: inherit !important;
  font-size: 18px !important;
  font-weight: 500 !important; }

.Toastify__toast--error {
  background-color: white !important;
  border: 2px solid #D00B0B;
  color: #D00B0B;
  border-radius: 4px !important;
  font-size: 15px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 600; }

.Toastify__toast {
  min-height: 56px !important;
  margin-top: 100px !important;
  margin-bottom: -90px !important;
  padding: 16px !important; }

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px; }

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px); } }

@media (max-width: 479px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px); } }

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(24px / 2 * -1);
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 24px;
        grid-gap: 24px;
        grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
        grid-template-columns: repeat(12, minmax(0, 1fr)); } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(16px / 2 * -1);
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
        grid-template-columns: repeat(8, minmax(0, 1fr)); } } }

@media (max-width: 479px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(16px / 2 * -1);
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
        grid-template-columns: repeat(4, minmax(0, 1fr)); } } }

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.33333% - 24px);
    width: calc(33.33333% - 24px);
    width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(24px / 2);
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: calc(8.33333% - 24px);
      width: calc(8.33333% - 24px);
      width: calc(8.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-desktop {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: calc(16.66667% - 24px);
      width: calc(16.66667% - 24px);
      width: calc(16.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-desktop {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: calc(25% - 24px);
      width: calc(25% - 24px);
      width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-desktop {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: calc(33.33333% - 24px);
      width: calc(33.33333% - 24px);
      width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-desktop {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: calc(41.66667% - 24px);
      width: calc(41.66667% - 24px);
      width: calc(41.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-desktop {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: calc(50% - 24px);
      width: calc(50% - 24px);
      width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-desktop {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: calc(58.33333% - 24px);
      width: calc(58.33333% - 24px);
      width: calc(58.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-desktop {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: calc(66.66667% - 24px);
      width: calc(66.66667% - 24px);
      width: calc(66.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-desktop {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: calc(75% - 24px);
      width: calc(75% - 24px);
      width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-desktop {
          width: auto;
          grid-column-end: span 9; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: calc(83.33333% - 24px);
      width: calc(83.33333% - 24px);
      width: calc(83.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-desktop {
          width: auto;
          grid-column-end: span 10; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: calc(91.66667% - 24px);
      width: calc(91.66667% - 24px);
      width: calc(91.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-desktop {
          width: auto;
          grid-column-end: span 11; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: calc(100% - 24px);
      width: calc(100% - 24px);
      width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-desktop {
          width: auto;
          grid-column-end: span 12; } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(16px / 2);
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: calc(12.5% - 16px);
      width: calc(12.5% - 16px);
      width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-tablet {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: calc(25% - 16px);
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-tablet {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: calc(37.5% - 16px);
      width: calc(37.5% - 16px);
      width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-tablet {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: calc(50% - 16px);
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-tablet {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: calc(62.5% - 16px);
      width: calc(62.5% - 16px);
      width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-tablet {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: calc(75% - 16px);
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-tablet {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: calc(87.5% - 16px);
      width: calc(87.5% - 16px);
      width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-tablet {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-tablet {
          width: auto;
          grid-column-end: span 8; } } }

@media (max-width: 479px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(16px / 2);
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: calc(25% - 16px);
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-phone {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: calc(50% - 16px);
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-phone {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: calc(75% - 16px);
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-phone {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-phone {
          width: auto;
          grid-column-end: span 4; } } }

.mdc-layout-grid__cell--order-1 {
  order: 1; }

.mdc-layout-grid__cell--order-2 {
  order: 2; }

.mdc-layout-grid__cell--order-3 {
  order: 3; }

.mdc-layout-grid__cell--order-4 {
  order: 4; }

.mdc-layout-grid__cell--order-5 {
  order: 5; }

.mdc-layout-grid__cell--order-6 {
  order: 6; }

.mdc-layout-grid__cell--order-7 {
  order: 7; }

.mdc-layout-grid__cell--order-8 {
  order: 8; }

.mdc-layout-grid__cell--order-9 {
  order: 9; }

.mdc-layout-grid__cell--order-10 {
  order: 10; }

.mdc-layout-grid__cell--order-11 {
  order: 11; }

.mdc-layout-grid__cell--order-12 {
  order: 12; }

.mdc-layout-grid__cell--align-top {
  align-self: flex-start; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-top {
      align-self: start; } }

.mdc-layout-grid__cell--align-middle {
  align-self: center; }

.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-bottom {
      align-self: end; } }

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc( 72px * 12 + 24px * 11 + 24px * 2);
    width: calc( var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc( 72px * 8 + 16px * 7 + 16px * 2);
    width: calc( var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2); } }

@media (max-width: 479px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc( 72px * 4 + 16px * 3 + 16px * 2);
    width: calc( var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2); } }

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0; }

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto; }

@media (min-width: 840px) {
  .login-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0px;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 0px);
    max-width: 40%; } }

@media (max-width: 839px) and (min-width: 480px) {
  .login-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0px;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 0px);
    max-width: 40%; } }

@media (max-width: 479px) {
  .login-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0px;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 0px);
    max-width: 90%; } }

.fieldWidth {
  width: 20vw;
  min-width: 200px !important; }

.logo-loader {
  height: 100px; }

/*
 Some standard sized
*/
.mt-0 {
  margin-top: 0em !important; }

.mtf-0 {
  margin-top: 0em !important; }

.mb-0 {
  margin-bottom: 0em !important; }

.mr-0 {
  margin-right: 0em !important; }

.ml-0 {
  margin-left: 0em !important; }

.mth-0 {
  margin-top: 0em; }

.mxf-0 {
  margin-left: 0em;
  margin-right: 0em; }

.myf-0 {
  margin-top: 0em;
  margin-bottom: 0em; }

.mx-0 {
  margin-right: 0em;
  margin-left: 0em; }

.my-0 {
  margin-top: 0em;
  margin-bottom: 0em; }

.pt-0 {
  padding-top: 0em; }

.pb-0 {
  padding-bottom: 0em; }

.pr-0 {
  padding-right: 0em; }

.pl-0 {
  padding-left: 0em; }

.px-0 {
  padding-right: 0em;
  padding-left: 0em; }

.py-0 {
  padding-top: 0em;
  padding-bottom: 0em; }

.mt-1 {
  margin-top: 1em !important; }

.mtf-1 {
  margin-top: 0.2em !important; }

.mb-1 {
  margin-bottom: 1em !important; }

.mr-1 {
  margin-right: 1em !important; }

.ml-1 {
  margin-left: 1em !important; }

.mth-1 {
  margin-top: 0.5em; }

.mxf-1 {
  margin-left: 0.5em;
  margin-right: 0.5em; }

.myf-1 {
  margin-top: 0.5em;
  margin-bottom: 0.5em; }

.mx-1 {
  margin-right: 1em;
  margin-left: 1em; }

.my-1 {
  margin-top: 1em;
  margin-bottom: 1em; }

.pt-1 {
  padding-top: 1em; }

.pb-1 {
  padding-bottom: 1em; }

.pr-1 {
  padding-right: 1em; }

.pl-1 {
  padding-left: 1em; }

.px-1 {
  padding-right: 1em;
  padding-left: 1em; }

.py-1 {
  padding-top: 1em;
  padding-bottom: 1em; }

.mt-2 {
  margin-top: 2em !important; }

.mtf-2 {
  margin-top: 0.4em !important; }

.mb-2 {
  margin-bottom: 2em !important; }

.mr-2 {
  margin-right: 2em !important; }

.ml-2 {
  margin-left: 2em !important; }

.mth-2 {
  margin-top: 1em; }

.mxf-2 {
  margin-left: 1em;
  margin-right: 1em; }

.myf-2 {
  margin-top: 1em;
  margin-bottom: 1em; }

.mx-2 {
  margin-right: 2em;
  margin-left: 2em; }

.my-2 {
  margin-top: 2em;
  margin-bottom: 2em; }

.pt-2 {
  padding-top: 2em; }

.pb-2 {
  padding-bottom: 2em; }

.pr-2 {
  padding-right: 2em; }

.pl-2 {
  padding-left: 2em; }

.px-2 {
  padding-right: 2em;
  padding-left: 2em; }

.py-2 {
  padding-top: 2em;
  padding-bottom: 2em; }

.mt-3 {
  margin-top: 3em !important; }

.mtf-3 {
  margin-top: 0.6em !important; }

.mb-3 {
  margin-bottom: 3em !important; }

.mr-3 {
  margin-right: 3em !important; }

.ml-3 {
  margin-left: 3em !important; }

.mth-3 {
  margin-top: 1.5em; }

.mxf-3 {
  margin-left: 1.5em;
  margin-right: 1.5em; }

.myf-3 {
  margin-top: 1.5em;
  margin-bottom: 1.5em; }

.mx-3 {
  margin-right: 3em;
  margin-left: 3em; }

.my-3 {
  margin-top: 3em;
  margin-bottom: 3em; }

.pt-3 {
  padding-top: 3em; }

.pb-3 {
  padding-bottom: 3em; }

.pr-3 {
  padding-right: 3em; }

.pl-3 {
  padding-left: 3em; }

.px-3 {
  padding-right: 3em;
  padding-left: 3em; }

.py-3 {
  padding-top: 3em;
  padding-bottom: 3em; }

.mt-4 {
  margin-top: 4em !important; }

.mtf-4 {
  margin-top: 0.8em !important; }

.mb-4 {
  margin-bottom: 4em !important; }

.mr-4 {
  margin-right: 4em !important; }

.ml-4 {
  margin-left: 4em !important; }

.mth-4 {
  margin-top: 2em; }

.mxf-4 {
  margin-left: 2em;
  margin-right: 2em; }

.myf-4 {
  margin-top: 2em;
  margin-bottom: 2em; }

.mx-4 {
  margin-right: 4em;
  margin-left: 4em; }

.my-4 {
  margin-top: 4em;
  margin-bottom: 4em; }

.pt-4 {
  padding-top: 4em; }

.pb-4 {
  padding-bottom: 4em; }

.pr-4 {
  padding-right: 4em; }

.pl-4 {
  padding-left: 4em; }

.px-4 {
  padding-right: 4em;
  padding-left: 4em; }

.py-4 {
  padding-top: 4em;
  padding-bottom: 4em; }

.mt-5 {
  margin-top: 5em !important; }

.mtf-5 {
  margin-top: 1em !important; }

.mb-5 {
  margin-bottom: 5em !important; }

.mr-5 {
  margin-right: 5em !important; }

.ml-5 {
  margin-left: 5em !important; }

.mth-5 {
  margin-top: 2.5em; }

.mxf-5 {
  margin-left: 2.5em;
  margin-right: 2.5em; }

.myf-5 {
  margin-top: 2.5em;
  margin-bottom: 2.5em; }

.mx-5 {
  margin-right: 5em;
  margin-left: 5em; }

.my-5 {
  margin-top: 5em;
  margin-bottom: 5em; }

.pt-5 {
  padding-top: 5em; }

.pb-5 {
  padding-bottom: 5em; }

.pr-5 {
  padding-right: 5em; }

.pl-5 {
  padding-left: 5em; }

.px-5 {
  padding-right: 5em;
  padding-left: 5em; }

.py-5 {
  padding-top: 5em;
  padding-bottom: 5em; }

@-webkit-keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
            transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
            transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@-webkit-keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: 0;
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: 0;
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@-webkit-keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

.mdc-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  -webkit-user-select: none;
          user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 4px; }
  .mdc-button::before, .mdc-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-button::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-button.mdc-ripple-upgraded::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-button.mdc-ripple-upgraded--unbounded::after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: 0;
    left: var(--mdc-ripple-left, 0); }
  .mdc-button.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button::before, .mdc-button::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-button.mdc-ripple-upgraded::after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .mdc-button:active {
    outline: none; }
  .mdc-button:hover {
    cursor: pointer; }
  .mdc-button:disabled {
    background-color: transparent;
    color: rgba(0, 33, 113, 0.37);
    cursor: default;
    pointer-events: none; }
  .mdc-button.mdc-button--dense {
    border-radius: 4px; }
  .mdc-button:not(:disabled) {
    background-color: transparent; }
  .mdc-button:not(:disabled) {
    color: #0d47a1;
    /* @alternate */
    color: #0d47a1;
    color: var(--mdc-theme-primary, #0d47a1); }
  .mdc-button::before, .mdc-button::after {
    background-color: #0d47a1; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button::before, .mdc-button::after {
        /* @alternate */
        background-color: #0d47a1;
        background-color: var(--mdc-theme-primary, #0d47a1); } }
  .mdc-button:hover::before {
    opacity: 0.04; }
  .mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-button .mdc-button__icon {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    vertical-align: top; }
    [dir="rtl"] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir="rtl"] {
      /* @noflip */
      margin-left: 8px;
      /* @noflip */
      margin-right: 0; }

.mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0; }
  [dir="rtl"] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px; }

svg.mdc-button__icon {
  fill: currentColor; }

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px; }
  [dir="rtl"] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--unelevated .mdc-button__icon,
  .mdc-button--unelevated .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--outlined .mdc-button__icon,
  .mdc-button--outlined .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: -4px; }

.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px; }
  [dir="rtl"] .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
  .mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--outlined .mdc-button__label + .mdc-button__icon,
  .mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: -4px;
    /* @noflip */
    margin-right: 8px; }

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px; }
  .mdc-button--raised:disabled,
  .mdc-button--unelevated:disabled {
    background-color: rgba(0, 33, 113, 0.12);
    color: rgba(0, 33, 113, 0.37); }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    background-color: #0d47a1; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised:not(:disabled),
      .mdc-button--unelevated:not(:disabled) {
        /* @alternate */
        background-color: #0d47a1;
        background-color: var(--mdc-theme-primary, #0d47a1); } }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    color: #ffffff;
    /* @alternate */
    color: #ffffff;
    color: var(--mdc-theme-on-primary, #ffffff); }
  .mdc-button--raised::before, .mdc-button--raised::after,
  .mdc-button--unelevated::before,
  .mdc-button--unelevated::after {
    background-color: #ffffff; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised::before, .mdc-button--raised::after,
      .mdc-button--unelevated::before,
      .mdc-button--unelevated::after {
        /* @alternate */
        background-color: #ffffff;
        background-color: var(--mdc-theme-on-primary, #ffffff); } }
  .mdc-button--raised:hover::before,
  .mdc-button--unelevated:hover::before {
    opacity: 0.08; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
  .mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-button--raised:not(.mdc-ripple-upgraded)::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-button--raised.mdc-ripple-upgraded,
  .mdc-button--unelevated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-button--raised:hover, .mdc-button--raised:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:disabled {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mdc-button--outlined {
  border-style: solid;
  padding: 0 14px 0 14px;
  border-width: 2px; }
  .mdc-button--outlined:disabled {
    border-color: rgba(0, 33, 113, 0.37); }
  .mdc-button--outlined:not(:disabled) {
    border-color: #0d47a1;
    /* @alternate */
    border-color: #0d47a1;
    border-color: var(--mdc-theme-primary, #0d47a1); }

.mdc-button--dense {
  height: 32px;
  font-size: .8125rem; }

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px; }

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px); } }

@media (max-width: 479px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px); } }

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(24px / 2 * -1);
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 24px;
        grid-gap: 24px;
        grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
        grid-template-columns: repeat(12, minmax(0, 1fr)); } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(16px / 2 * -1);
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
        grid-template-columns: repeat(8, minmax(0, 1fr)); } } }

@media (max-width: 479px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(16px / 2 * -1);
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
        grid-template-columns: repeat(4, minmax(0, 1fr)); } } }

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.33333% - 24px);
    width: calc(33.33333% - 24px);
    width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(24px / 2);
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: calc(8.33333% - 24px);
      width: calc(8.33333% - 24px);
      width: calc(8.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-desktop {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: calc(16.66667% - 24px);
      width: calc(16.66667% - 24px);
      width: calc(16.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-desktop {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: calc(25% - 24px);
      width: calc(25% - 24px);
      width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-desktop {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: calc(33.33333% - 24px);
      width: calc(33.33333% - 24px);
      width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-desktop {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: calc(41.66667% - 24px);
      width: calc(41.66667% - 24px);
      width: calc(41.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-desktop {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: calc(50% - 24px);
      width: calc(50% - 24px);
      width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-desktop {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: calc(58.33333% - 24px);
      width: calc(58.33333% - 24px);
      width: calc(58.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-desktop {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: calc(66.66667% - 24px);
      width: calc(66.66667% - 24px);
      width: calc(66.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-desktop {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: calc(75% - 24px);
      width: calc(75% - 24px);
      width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-desktop {
          width: auto;
          grid-column-end: span 9; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: calc(83.33333% - 24px);
      width: calc(83.33333% - 24px);
      width: calc(83.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-desktop {
          width: auto;
          grid-column-end: span 10; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: calc(91.66667% - 24px);
      width: calc(91.66667% - 24px);
      width: calc(91.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-desktop {
          width: auto;
          grid-column-end: span 11; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: calc(100% - 24px);
      width: calc(100% - 24px);
      width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-desktop {
          width: auto;
          grid-column-end: span 12; } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(16px / 2);
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: calc(12.5% - 16px);
      width: calc(12.5% - 16px);
      width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-tablet {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: calc(25% - 16px);
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-tablet {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: calc(37.5% - 16px);
      width: calc(37.5% - 16px);
      width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-tablet {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: calc(50% - 16px);
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-tablet {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: calc(62.5% - 16px);
      width: calc(62.5% - 16px);
      width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-tablet {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: calc(75% - 16px);
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-tablet {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: calc(87.5% - 16px);
      width: calc(87.5% - 16px);
      width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-tablet {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-tablet {
          width: auto;
          grid-column-end: span 8; } } }

@media (max-width: 479px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(16px / 2);
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: calc(25% - 16px);
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-phone {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: calc(50% - 16px);
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-phone {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: calc(75% - 16px);
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-phone {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: calc(100% - 16px);
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-phone {
          width: auto;
          grid-column-end: span 4; } } }

.mdc-layout-grid__cell--order-1 {
  order: 1; }

.mdc-layout-grid__cell--order-2 {
  order: 2; }

.mdc-layout-grid__cell--order-3 {
  order: 3; }

.mdc-layout-grid__cell--order-4 {
  order: 4; }

.mdc-layout-grid__cell--order-5 {
  order: 5; }

.mdc-layout-grid__cell--order-6 {
  order: 6; }

.mdc-layout-grid__cell--order-7 {
  order: 7; }

.mdc-layout-grid__cell--order-8 {
  order: 8; }

.mdc-layout-grid__cell--order-9 {
  order: 9; }

.mdc-layout-grid__cell--order-10 {
  order: 10; }

.mdc-layout-grid__cell--order-11 {
  order: 11; }

.mdc-layout-grid__cell--order-12 {
  order: 12; }

.mdc-layout-grid__cell--align-top {
  align-self: flex-start; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-top {
      align-self: start; } }

.mdc-layout-grid__cell--align-middle {
  align-self: center; }

.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-bottom {
      align-self: end; } }

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc( 72px * 12 + 24px * 11 + 24px * 2);
    width: calc( var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc( 72px * 8 + 16px * 7 + 16px * 2);
    width: calc( var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2); } }

@media (max-width: 479px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc( 72px * 4 + 16px * 3 + 16px * 2);
    width: calc( var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2); } }

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0; }

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto; }

.mdc-typography {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.mdc-typography--headline1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 300;
  letter-spacing: -0.01562em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.00833em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline3 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3rem;
  line-height: 3.125rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline4 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.00735em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline5 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline6 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--caption {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-typography--overline {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.16667em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer; }

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

.mdc-text-field-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-text-field-helper-text::before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: "";
    vertical-align: 0; }

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-floating-label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  position: absolute;
  bottom: 8px;
  left: 0;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  /* @alternate */
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  will-change: transform; }
  [dir="rtl"] .mdc-floating-label, .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    right: 0;
    /* @noflip */
    left: auto;
    /* @noflip */
    -webkit-transform-origin: right top;
            transform-origin: right top;
    /* @noflip */
    text-align: right; }

.mdc-floating-label--float-above {
  cursor: auto; }

.mdc-floating-label--float-above {
  -webkit-transform: translateY(-100%) scale(0.75);
          transform: translateY(-100%) scale(0.75); }

.mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-standard 250ms 1;
          animation: mdc-floating-label-shake-float-above-standard 250ms 1; }

@-webkit-keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); } }

.mdc-line-ripple {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 2; }

.mdc-line-ripple--active {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  opacity: 1; }

.mdc-line-ripple--deactivating {
  opacity: 0; }

.mdc-notched-outline,
.mdc-notched-outline__idle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

.mdc-notched-outline {
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  text-align: left;
  overflow: hidden; }
  [dir="rtl"] .mdc-notched-outline, .mdc-notched-outline[dir="rtl"] {
    /* @noflip */
    text-align: right; }
  .mdc-notched-outline svg {
    position: absolute;
    width: 100%;
    height: 100%; }

.mdc-notched-outline__idle {
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid;
  opacity: 1; }

.mdc-notched-outline__path {
  stroke-width: 1px;
  transition: stroke 150ms cubic-bezier(0.4, 0, 0.2, 1), stroke-width 150ms cubic-bezier(0.4, 0, 0.2, 1);
  fill: transparent; }

.mdc-notched-outline--notched {
  opacity: 1; }

.mdc-notched-outline--notched ~ .mdc-notched-outline__idle {
  opacity: 0; }

.mdc-text-field-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-text-field-helper-text::before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: "";
    vertical-align: 0; }

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer; }

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

.mdc-text-field {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  will-change: opacity, transform, color; }
  .mdc-text-field::before, .mdc-text-field::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-text-field::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-text-field.mdc-ripple-upgraded::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-text-field.mdc-ripple-upgraded--unbounded::after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: 0;
    left: var(--mdc-ripple-left, 0); }
  .mdc-text-field.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-text-field.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field::before, .mdc-text-field::after {
    background-color: rgba(0, 33, 113, 0.87); }
  .mdc-text-field:hover::before {
    opacity: 0.04; }
  .mdc-text-field:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-text-field::before, .mdc-text-field::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-text-field.mdc-ripple-upgraded::after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0, 33, 113, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::-webkit-input-placeholder {
    color: rgba(0, 33, 113, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 33, 113, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: rgba(0, 33, 113, 0.87); }
  .mdc-text-field .mdc-text-field__input {
    caret-color: #0d47a1;
    /* @alternate */
    caret-color: #0d47a1;
    caret-color: var(--mdc-theme-primary, #0d47a1); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
    border-bottom-color: rgba(0, 33, 113, 0.42); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
    border-bottom-color: rgba(0, 33, 113, 0.87); }
  .mdc-text-field .mdc-line-ripple {
    background-color: #0d47a1;
    /* @alternate */
    background-color: #0d47a1;
    background-color: var(--mdc-theme-primary, #0d47a1); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(0, 33, 113, 0.12); }
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-text {
    color: rgba(0, 33, 113, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
    color: rgba(0, 33, 113, 0.54); }
  .mdc-text-field:not(.mdc-text-field--disabled) {
    background-color: #f5f6f9; }
  .mdc-text-field .mdc-floating-label {
    /* @noflip */
    left: 12px;
    /* @noflip */
    right: initial;
    bottom: 20px;
    pointer-events: none; }
    [dir="rtl"] .mdc-text-field .mdc-floating-label, .mdc-text-field .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 12px; }
  .mdc-text-field:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-floating-label {
    max-width: calc(100% - 48px); }
  .mdc-text-field .mdc-floating-label--float-above {
    -webkit-transform: translateY(-50%) scale(0.75);
            transform: translateY(-50%) scale(0.75); }
  .mdc-text-field .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-box 250ms 1;
            animation: mdc-floating-label-shake-float-above-text-field-box 250ms 1; }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-box {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-box {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); } }

.mdc-text-field__input {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 12px 6px;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
          appearance: none; }
  .mdc-text-field__input::-webkit-input-placeholder {
    -webkit-transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }
  .mdc-text-field__input::placeholder {
    transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }
  .mdc-text-field__input:focus {
    outline: none; }
  .mdc-text-field__input:invalid {
    box-shadow: none; }

.mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
  -webkit-transform: translateY(-50%) scale(0.75);
          transform: translateY(-50%) scale(0.75);
  cursor: auto; }

.mdc-text-field--outlined {
  border: none;
  overflow: visible; }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
    border-color: rgba(0, 33, 113, 0.24); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
    stroke: rgba(0, 33, 113, 0.24); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
    border-color: rgba(0, 33, 113, 0.87); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
    stroke: rgba(0, 33, 113, 0.87); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
    stroke: #0d47a1;
    /* @alternate */
    stroke: #0d47a1;
    stroke: var(--mdc-theme-primary, #0d47a1); }
  .mdc-text-field--outlined .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
            animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-text-field--outlined .mdc-notched-outline {
    border-radius: 4px; }
  .mdc-text-field--outlined .mdc-notched-outline__idle {
    border-radius: 4px; }
  .mdc-text-field--outlined .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) scale(0.75);
            transform: translateY(-130%) scale(0.75); }
  .mdc-text-field--outlined::before, .mdc-text-field--outlined::after {
    content: none; }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    background-color: transparent; }
  .mdc-text-field--outlined .mdc-text-field__input {
    display: flex;
    padding: 12px 16px 14px;
    border: none !important;
    background-color: transparent;
    z-index: 1; }
  .mdc-text-field--outlined .mdc-text-field__icon {
    z-index: 2; }
  .mdc-text-field--outlined .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    width: auto; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__path {
  stroke-width: 2px; }

.mdc-text-field--outlined.mdc-text-field--disabled {
  background-color: transparent; }
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__idle {
    border-color: rgba(0, 33, 113, 0.06); }
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__path {
    stroke: rgba(0, 33, 113, 0.06); }
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: none; }

.mdc-text-field--outlined.mdc-text-field--dense {
  height: 48px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
    -webkit-transform: translateY(-110%) scale(0.923);
            transform: translateY(-110%) scale(0.923); }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1;
            animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
    padding: 12px 12px 7px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
    bottom: 16px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
    top: 12px; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 16px; }

.mdc-text-field--with-leading-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-floating-label, .mdc-text-field--with-leading-icon .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  -webkit-transform: translateY(-130%) translateX(-32px) scale(0.75);
          transform: translateY(-130%) translateX(-32px) scale(0.75); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir="rtl"] {
    -webkit-transform: translateY(-130%) translateX(32px) scale(0.75);
            transform: translateY(-130%) translateX(32px) scale(0.75); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir="rtl"] .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  -webkit-transform: translateY(-110%) translateX(-21px) scale(0.923);
          transform: translateY(-110%) translateX(-21px) scale(0.923); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir="rtl"] {
    -webkit-transform: translateY(-110%) translateX(21px) scale(0.923);
            transform: translateY(-110%) translateX(21px) scale(0.923); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir="rtl"] .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1; }

.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 12px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 12px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 16px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: auto; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 16px; }
  .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon {
    /* @noflip */
    right: 12px;
    /* @noflip */
    left: auto; }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon[dir="rtl"] {
      /* @noflip */
      right: auto;
      /* @noflip */
      left: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: auto; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 38px;
  /* @noflip */
  padding-right: 12px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 38px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 38px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 38px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 12px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 38px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 38px;
    /* @noflip */
    padding-right: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: auto; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 12px; }
  .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon {
    /* @noflip */
    right: 12px;
    /* @noflip */
    left: auto; }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon[dir="rtl"] {
      /* @noflip */
      right: auto;
      /* @noflip */
      left: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 38px;
  /* @noflip */
  padding-right: 38px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 38px;
    /* @noflip */
    padding-right: 38px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 38px;
  /* @noflip */
  right: auto; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 38px; }

.mdc-text-field--dense .mdc-floating-label--float-above {
  -webkit-transform: translateY(-70%) scale(0.923);
          transform: translateY(-70%) scale(0.923); }

.mdc-text-field--dense .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-dense 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-dense 250ms 1; }

.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 0; }

.mdc-text-field--dense .mdc-floating-label {
  font-size: .813rem; }

.mdc-text-field__input:required + .mdc-floating-label::after {
  margin-left: 1px;
  content: "*"; }

.mdc-text-field--textarea {
  border-radius: 4px;
  display: flex;
  width: auto;
  height: auto;
  transition: none;
  border: 1px solid;
  overflow: hidden; }
  .mdc-text-field--textarea .mdc-floating-label {
    border-radius: 4px 4px 0 0; }
  .mdc-text-field--textarea .mdc-text-field__input {
    border-radius: 2px; }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) {
    border-color: rgba(0, 33, 113, 0.73); }
    .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
      border-color: rgba(0, 33, 113, 0.73); }
  .mdc-text-field--textarea .mdc-floating-label--float-above {
    -webkit-transform: translateY(-50%) scale(0.923);
            transform: translateY(-50%) scale(0.923); }
  .mdc-text-field--textarea .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-textarea 250ms 1;
            animation: mdc-floating-label-shake-float-above-textarea 250ms 1; }
  .mdc-text-field--textarea::before, .mdc-text-field--textarea::after {
    content: none; }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) {
    background-color: transparent; }
  .mdc-text-field--textarea .mdc-text-field__input {
    align-self: auto;
    box-sizing: content-box;
    height: auto;
    margin: 0;
    padding: 16px;
    padding-top: 32px;
    border: 1px solid transparent; }
  .mdc-text-field--textarea .mdc-floating-label {
    background-color: white;
    /* @noflip */
    left: 1px;
    /* @noflip */
    right: 0;
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: 0;
    top: 18px;
    bottom: auto;
    width: auto;
    margin-top: 2px;
    padding: 12px 8px 8px 8px;
    line-height: 1.15;
    pointer-events: none; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: 0;
      /* @noflip */
      right: 1px; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 8px; }

.mdc-text-field--fullwidth {
  width: 100%; }
  .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
    display: block; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::before, .mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::after {
      content: none; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
      background-color: transparent; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
      padding: 0; }

.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: #b00020;
  border-bottom-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--dense + .mdc-text-field-helper-text {
  margin-bottom: 4px; }

.mdc-text-field + .mdc-text-field-helper-text {
  margin-right: 12px;
  margin-left: 12px; }

.mdc-text-field--outlined + .mdc-text-field-helper-text {
  margin-right: 16px;
  margin-left: 16px; }

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start; }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(13, 71, 161, 0.87); }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::-webkit-input-placeholder {
  color: rgba(13, 71, 161, 0.87); }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: rgba(13, 71, 161, 0.87); }

.mdc-text-field--focused .mdc-text-field__input:required + .mdc-floating-label::after {
  color: #b00020;
  /* @alternate */
  color: #b00020;
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--focused + .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) {
  border-color: #0d47a1;
  /* @alternate */
  border-color: #0d47a1;
  border-color: var(--mdc-theme-primary, #0d47a1); }
  .mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
    border-color: #0d47a1;
    /* @alternate */
    border-color: #0d47a1;
    border-color: var(--mdc-theme-primary, #0d47a1); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: #b00020;
  border-bottom-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: #b00020;
  border-bottom-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: #b00020;
  /* @alternate */
  background-color: #b00020;
  background-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: #b00020;
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::-webkit-input-placeholder {
  color: #b00020;
  /* @alternate */
  color: #b00020;
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: #b00020;
  /* @alternate */
  color: #b00020;
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: #b00020;
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
  /* @alternate */
  caret-color: #b00020;
  caret-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--with-leading-icon):not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: #b00020;
  /* @alternate */
  color: #b00020;
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid.mdc-text-field--with-trailing-icon.mdc-text-field--with-leading-icon:not(.mdc-text-field--disabled) .mdc-text-field__icon ~ .mdc-text-field__icon {
  color: #b00020;
  /* @alternate */
  color: #b00020;
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
  border-color: #b00020;
  /* @alternate */
  border-color: #b00020;
  border-color: var(--mdc-theme-error, #b00020); }
  .mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
    border-color: #b00020;
    /* @alternate */
    border-color: #b00020;
    border-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
  border-color: #b00020;
  /* @alternate */
  border-color: #b00020;
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
  stroke: #b00020;
  /* @alternate */
  stroke: #b00020;
  stroke: var(--mdc-theme-error, #b00020); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
  border-color: #b00020;
  /* @alternate */
  border-color: #b00020;
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: #b00020;
  /* @alternate */
  stroke: #b00020;
  stroke: var(--mdc-theme-error, #b00020); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
  stroke: #b00020;
  /* @alternate */
  stroke: #b00020;
  stroke: var(--mdc-theme-error, #b00020); }

.mdc-text-field--disabled {
  background-color: #fafbfc;
  border-bottom: none;
  pointer-events: none; }
  .mdc-text-field--disabled .mdc-text-field__input {
    border-bottom-color: rgba(0, 33, 113, 0.06); }
  .mdc-text-field--disabled .mdc-text-field__input {
    color: rgba(0, 33, 113, 0.37); }
  .mdc-text-field--disabled .mdc-floating-label {
    color: rgba(0, 33, 113, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__input::-webkit-input-placeholder {
    color: rgba(0, 33, 113, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 33, 113, 0.37); }
  .mdc-text-field--disabled + .mdc-text-field-helper-text {
    color: rgba(0, 33, 113, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__icon {
    color: rgba(0, 33, 113, 0.3); }
  .mdc-text-field--disabled:not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(0, 33, 113, 0.12); }
  .mdc-text-field--disabled .mdc-floating-label {
    cursor: default; }

.mdc-text-field--textarea.mdc-text-field--disabled {
  border-color: rgba(0, 33, 113, 0.26);
  background-color: #f9f9f9;
  border-bottom-width: 1px;
  border-style: solid; }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input:focus {
    border-color: rgba(0, 33, 113, 0.26); }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input {
    border: 1px solid transparent; }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-floating-label {
    background-color: #f9f9f9; }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    -webkit-transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    -webkit-transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    -webkit-transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    -webkit-transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    -webkit-transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    -webkit-transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); } }

.material-icons--ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  .material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .material-icons--ripple-surface::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .material-icons--ripple-surface.mdc-ripple-upgraded::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .material-icons--ripple-surface.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .material-icons--ripple-surface.mdc-ripple-upgraded--unbounded::after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: 0;
    left: var(--mdc-ripple-left, 0); }
  .material-icons--ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .material-icons--ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .material-icons--ripple-surface.mdc-ripple-upgraded::after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
    background-color: #002171; }
  .material-icons--ripple-surface:hover::before {
    opacity: 0.04; }
  .material-icons--ripple-surface:not(.mdc-ripple-upgraded):focus::before, .material-icons--ripple-surface.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .material-icons--ripple-surface:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .material-icons--ripple-surface:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .material-icons--ripple-surface.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  width: 48px;
  height: 48px;
  padding: 12px;
  font-size: 24px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-icon-button::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-icon-button.mdc-ripple-upgraded::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: 0;
    left: var(--mdc-ripple-left, 0); }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button::before, .mdc-icon-button::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
    top: calc(50% - 50%);
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: calc(50% - 50%);
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button svg,
  .mdc-icon-button img {
    width: 24px;
    height: 24px; }
  .mdc-icon-button:disabled {
    color: rgba(0, 0, 0, 0.38);
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    background-color: #002171; }
  .mdc-icon-button:hover::before {
    opacity: 0.04; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before, .mdc-icon-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-icon-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-icon-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-icon-button__icon {
  display: inline-block; }
  .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: none; }

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none; }
  .mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: inline-block; }

.mdc-card {
  background-color: #ffffff;
  /* @alternate */
  background-color: #ffffff;
  background-color: var(--mdc-theme-surface, #ffffff);
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }

.mdc-card--outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #e0e4ee; }

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .mdc-card__media::before {
    display: block;
    content: ""; }

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit; }

.mdc-card__media--square::before {
  margin-top: 100%; }

.mdc-card__media--16-9::before {
  margin-top: 56.25%; }

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box; }

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden; }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-card__primary-action::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-card__primary-action.mdc-ripple-upgraded::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-card__primary-action.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: 0;
    left: var(--mdc-ripple-left, 0); }
  .mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-card__primary-action.mdc-ripple-upgraded::after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    background-color: #002171; }
  .mdc-card__primary-action:hover::before {
    opacity: 0.04; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before, .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-card__primary-action.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit; }

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px; }

.mdc-card__actions--full-bleed {
  padding: 0; }

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box; }

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
  flex-grow: 1;
  justify-content: flex-end; }

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0; }
  [dir="rtl"] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 16px; }

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; }
  .mdc-card__action:focus {
    outline: none; }

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px; }
  [dir="rtl"] .mdc-card__action--button, .mdc-card__action--button[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: 0; }
  .mdc-card__action--button:last-child {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 0; }

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  text-align: left; }
  [dir="rtl"] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir="rtl"] {
    text-align: right; }

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px; }

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  margin: 0;
  padding: 8px 0;
  /* @alternate */
  line-height: 1.5rem;
  list-style-type: none; }

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: rgba(0, 0, 0, 0.54);
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)); }

.mdc-list-item__graphic {
  background-color: transparent; }

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: .812rem; }

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 0 16px;
  overflow: hidden; }
  .mdc-list-item:focus {
    outline: none; }

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #0d47a1;
  /* @alternate */
  color: #0d47a1;
  color: var(--mdc-theme-primary, #0d47a1); }
  .mdc-list-item--selected .mdc-list-item__graphic,
  .mdc-list-item--activated .mdc-list-item__graphic {
    color: #0d47a1;
    /* @alternate */
    color: #0d47a1;
    color: var(--mdc-theme-primary, #0d47a1); }

.mdc-list-item--disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 32px;
    /* @noflip */
    margin-right: 0; }

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__meta,
  [dir="rtl"] .mdc-list-item .mdc-list-item__meta {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: auto; }

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
  display: block; }
  .mdc-list-item__primary-text::before {
    display: inline-block;
    width: 0;
    height: 32px;
    content: "";
    vertical-align: 0; }
  .mdc-list-item__primary-text::after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: -20px; }
  .mdc-list--dense .mdc-list-item__primary-text {
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    margin-bottom: -20px; }
    .mdc-list--dense .mdc-list-item__primary-text::before {
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
      vertical-align: 0; }
    .mdc-list--dense .mdc-list-item__primary-text::after {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: -20px; }

.mdc-list-item__secondary-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  display: block; }
  .mdc-list-item__secondary-text::before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: 0; }
  .mdc-list--dense .mdc-list-item__secondary-text {
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    font-size: inherit; }
    .mdc-list--dense .mdc-list-item__secondary-text::before {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: 0; }

.mdc-list--dense .mdc-list-item {
  height: 40px; }

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 36px;
  width: 20px;
  height: 20px;
  font-size: 20px; }
  .mdc-list-item[dir="rtl"] .mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 36px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--avatar-list .mdc-list-item {
  height: 56px; }

.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  font-size: 40px;
  border-radius: 50%; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start; }

.mdc-list--two-line .mdc-list-item {
  height: 72px; }

.mdc-list--two-line.mdc-list--dense .mdc-list-item {
  height: 60px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 20px;
  width: 36px;
  height: 36px;
  font-size: 36px; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 20px;
    /* @noflip */
    margin-right: 0; }

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded::after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: 0;
    left: var(--mdc-ripple-left, 0); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    background-color: #002171; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:hover::before {
    opacity: 0.04; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before {
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
    background-color: #0d47a1; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
        /* @alternate */
        background-color: #0d47a1;
        background-color: var(--mdc-theme-primary, #0d47a1); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:hover::before {
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.28; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.28; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before {
    opacity: 0.08; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
    background-color: #0d47a1; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
        /* @alternate */
        background-color: #0d47a1;
        background-color: var(--mdc-theme-primary, #0d47a1); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover::before {
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.2; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.24; }

a.mdc-list-item {
  color: inherit;
  text-decoration: none; }

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mdc-list-divider--padded {
  margin: 0 16px; }

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px); }
  .mdc-list-group[dir="rtl"] .mdc-list-divider--inset,
  [dir="rtl"] .mdc-list-group .mdc-list-divider--inset {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 72px; }

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px); }

.mdc-list-group .mdc-list {
  padding: 0; }

.mdc-list-group__subheader {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0.75rem 16px; }

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

.mdc-dialog {
  display: none;
  z-index: 7; }
  .mdc-dialog .mdc-dialog__surface {
    background-color: #ffffff;
    /* @alternate */
    background-color: #ffffff;
    background-color: var(--mdc-theme-surface, #ffffff); }
  .mdc-dialog .mdc-dialog__scrim {
    background-color: rgba(0, 33, 113, 0.32); }
  .mdc-dialog .mdc-dialog__title {
    color: rgba(0, 33, 113, 0.87); }
  .mdc-dialog .mdc-dialog__content {
    color: rgba(0, 33, 113, 0.6); }
  .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title,
  .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions {
    border-color: rgba(0, 33, 113, 0.12); }
  .mdc-dialog .mdc-dialog__surface {
    min-width: 280px; }
  @media (max-width: 592px) {
    .mdc-dialog .mdc-dialog__surface {
      max-width: calc(100vw - 32px); } }
  @media (min-width: 592px) {
    .mdc-dialog .mdc-dialog__surface {
      max-width: 560px; } }
  .mdc-dialog .mdc-dialog__surface {
    max-height: calc(100vh - 32px); }
  .mdc-dialog .mdc-dialog__surface {
    border-radius: 4px; }

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1; }

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  opacity: 0; }

.mdc-dialog__surface {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__surface,
  [dir="rtl"] .mdc-dialog .mdc-dialog__surface {
    text-align: right; }

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent; }
  .mdc-dialog__title::before {
    display: inline-block;
    width: 0;
    height: 40px;
    content: "";
    vertical-align: 0; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__title,
  [dir="rtl"] .mdc-dialog .mdc-dialog__title {
    text-align: right; }

.mdc-dialog--scrollable .mdc-dialog__title {
  padding-bottom: 15px; }

.mdc-dialog__content {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .mdc-dialog__content > :first-child {
    margin-top: 0; }
  .mdc-dialog__content > :last-child {
    margin-bottom: 0; }

.mdc-dialog__title + .mdc-dialog__content {
  padding-top: 0; }

.mdc-dialog--scrollable .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px; }

.mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 6px 0 0; }

.mdc-dialog--scrollable .mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 0; }

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent; }
  .mdc-dialog--stacked .mdc-dialog__actions {
    flex-direction: column;
    align-items: flex-end; }

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  text-align: right; }
  [dir="rtl"] .mdc-dialog__button, .mdc-dialog__button[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px; }
  .mdc-dialog__button:first-child {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 0; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__button,
  [dir="rtl"] .mdc-dialog .mdc-dialog__button {
    text-align: left; }
  .mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
    margin-top: 12px; }

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex; }

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear; }

.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, -webkit-transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear; }

.mdc-dialog--closing .mdc-dialog__container {
  -webkit-transform: scale(1);
          transform: scale(1); }

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1; }

.mdc-dialog--open .mdc-dialog__container {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.mdc-dialog-scroll-lock {
  overflow: hidden; }

@-webkit-keyframes primary-indeterminate-translate {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(83.67142%);
            transform: translateX(83.67142%); }
  100% {
    -webkit-transform: translateX(200.61106%);
            transform: translateX(200.61106%); } }

@keyframes primary-indeterminate-translate {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(83.67142%);
            transform: translateX(83.67142%); }
  100% {
    -webkit-transform: translateX(200.61106%);
            transform: translateX(200.61106%); } }

@-webkit-keyframes primary-indeterminate-scale {
  0% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  36.65% {
    -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
            animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
            animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    -webkit-transform: scaleX(0.66148);
            transform: scaleX(0.66148); }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); } }

@keyframes primary-indeterminate-scale {
  0% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  36.65% {
    -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
            animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
            animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    -webkit-transform: scaleX(0.66148);
            transform: scaleX(0.66148); }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); } }

@-webkit-keyframes secondary-indeterminate-translate {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
            animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
            animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(37.65191%);
            transform: translateX(37.65191%); }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
            animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(84.38617%);
            transform: translateX(84.38617%); }
  100% {
    -webkit-transform: translateX(160.27778%);
            transform: translateX(160.27778%); } }

@keyframes secondary-indeterminate-translate {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
            animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
            animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(37.65191%);
            transform: translateX(37.65191%); }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
            animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(84.38617%);
            transform: translateX(84.38617%); }
  100% {
    -webkit-transform: translateX(160.27778%);
            transform: translateX(160.27778%); } }

@-webkit-keyframes secondary-indeterminate-scale {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
            animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
            animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    -webkit-transform: scaleX(0.4571);
            transform: scaleX(0.4571); }
  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
            animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    -webkit-transform: scaleX(0.72796);
            transform: scaleX(0.72796); }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); } }

@keyframes secondary-indeterminate-scale {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
            animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
            animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    -webkit-transform: scaleX(0.4571);
            transform: scaleX(0.4571); }
  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
            animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    -webkit-transform: scaleX(0.72796);
            transform: scaleX(0.72796); }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); } }

@-webkit-keyframes buffering {
  to {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); } }

@keyframes buffering {
  to {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); } }

@-webkit-keyframes primary-indeterminate-translate-reverse {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(-83.67142%);
            transform: translateX(-83.67142%); }
  100% {
    -webkit-transform: translateX(-200.61106%);
            transform: translateX(-200.61106%); } }

@keyframes primary-indeterminate-translate-reverse {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(-83.67142%);
            transform: translateX(-83.67142%); }
  100% {
    -webkit-transform: translateX(-200.61106%);
            transform: translateX(-200.61106%); } }

@-webkit-keyframes secondary-indeterminate-translate-reverse {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
            animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
            animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(-37.65191%);
            transform: translateX(-37.65191%); }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
            animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(-84.38617%);
            transform: translateX(-84.38617%); }
  100% {
    -webkit-transform: translateX(-160.27778%);
            transform: translateX(-160.27778%); } }

@keyframes secondary-indeterminate-translate-reverse {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
            animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
            animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(-37.65191%);
            transform: translateX(-37.65191%); }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
            animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(-84.38617%);
            transform: translateX(-84.38617%); }
  100% {
    -webkit-transform: translateX(-160.27778%);
            transform: translateX(-160.27778%); } }

@-webkit-keyframes buffering-reverse {
  to {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); } }

@keyframes buffering-reverse {
  to {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); } }

.mdc-linear-progress {
  position: relative;
  width: 100%;
  height: 4px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  overflow: hidden; }
  .mdc-linear-progress__bar {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation: none;
            animation: none;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-linear-progress__bar-inner {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation: none;
            animation: none; }
  .mdc-linear-progress__buffering-dots {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation: buffering 250ms infinite linear;
            animation: buffering 250ms infinite linear;
    background-repeat: repeat-x;
    background-size: 10px 4px; }
  .mdc-linear-progress__buffer {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-linear-progress__primary-bar {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  .mdc-linear-progress__secondary-bar {
    visibility: hidden; }
  .mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
    transition: none; }
  .mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
    left: -145.166611%;
    -webkit-animation: primary-indeterminate-translate 2s infinite linear;
            animation: primary-indeterminate-translate 2s infinite linear; }
    .mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
      -webkit-animation: primary-indeterminate-scale 2s infinite linear;
              animation: primary-indeterminate-scale 2s infinite linear; }
  .mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
    left: -54.888891%;
    -webkit-animation: secondary-indeterminate-translate 2s infinite linear;
            animation: secondary-indeterminate-translate 2s infinite linear;
    visibility: visible; }
    .mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
      -webkit-animation: secondary-indeterminate-scale 2s infinite linear;
              animation: secondary-indeterminate-scale 2s infinite linear; }
  .mdc-linear-progress--reversed .mdc-linear-progress__bar,
  .mdc-linear-progress--reversed .mdc-linear-progress__buffer {
    right: 0;
    -webkit-transform-origin: center right;
            transform-origin: center right; }
  .mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
    -webkit-animation-name: primary-indeterminate-translate-reverse;
            animation-name: primary-indeterminate-translate-reverse; }
  .mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
    -webkit-animation-name: secondary-indeterminate-translate-reverse;
            animation-name: secondary-indeterminate-translate-reverse; }
  .mdc-linear-progress--reversed .mdc-linear-progress__buffering-dots {
    -webkit-animation: buffering-reverse 250ms infinite linear;
            animation: buffering-reverse 250ms infinite linear; }
  .mdc-linear-progress--closed {
    opacity: 0; }

.mdc-linear-progress__bar-inner {
  background-color: #0d47a1;
  /* @alternate */
  background-color: #0d47a1;
  background-color: var(--mdc-theme-primary, #0d47a1); }

.mdc-linear-progress__buffering-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E"); }

.mdc-linear-progress__buffer {
  background-color: #e6e6e6; }

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
  right: -145.166611%;
  left: auto; }

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
  right: -54.888891%;
  left: auto; }

@-webkit-keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: 29.78334; }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  100% {
    stroke-dashoffset: 0; } }

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: 29.78334; }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  100% {
    stroke-dashoffset: 0; } }

@-webkit-keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  68.2% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  68.2% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0; }
  to {
    opacity: 0;
    stroke-dashoffset: -29.78334; } }

@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0; }
  to {
    opacity: 0;
    stroke-dashoffset: -29.78334; } }

@-webkit-keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; } }

@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; } }

@-webkit-keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 1; } }

@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 1; } }

@-webkit-keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    -webkit-animation-timing-function: mdc-animation-deceleration-curve-timing-function;
            animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; } }

@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    -webkit-animation-timing-function: mdc-animation-deceleration-curve-timing-function;
            animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; } }

@-webkit-keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    opacity: 0; } }

@-webkit-keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1; }
  32.8%,
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1; }
  32.8%,
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 0; } }

.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 11px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  .mdc-checkbox::before, .mdc-checkbox::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-checkbox::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-checkbox.mdc-ripple-upgraded::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-checkbox.mdc-ripple-upgraded--unbounded::after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: 0;
    left: var(--mdc-ripple-left, 0); }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox::before, .mdc-checkbox::after {
    background-color: #78909c; }
    @supports not (-ms-ime-align: auto) {
      .mdc-checkbox::before, .mdc-checkbox::after {
        /* @alternate */
        background-color: #78909c;
        background-color: var(--mdc-theme-secondary, #78909c); } }
  .mdc-checkbox:hover::before {
    opacity: 0.04; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):focus::before, .mdc-checkbox.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-checkbox:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-checkbox.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-checkbox::before, .mdc-checkbox::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-checkbox.mdc-ripple-upgraded::before, .mdc-checkbox.mdc-ripple-upgraded::after {
    top: calc(50% - 50%);
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: calc(50% - 50%);
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }

.mdc-checkbox__checkmark {
  color: #ffffff; }

.mdc-checkbox__mixedmark {
  border-color: #ffffff; }

.mdc-checkbox__background::before {
  background-color: #78909c; }
  @supports not (-ms-ime-align: auto) {
    .mdc-checkbox__background::before {
      /* @alternate */
      background-color: #78909c;
      background-color: var(--mdc-theme-secondary, #78909c); } }

.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 33, 113, 0.54);
  background-color: transparent; }

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #78909c;
  /* @alternate */
  border-color: #78909c;
  border-color: var(--mdc-theme-secondary, #78909c);
  background-color: #78909c;
  /* @alternate */
  background-color: #78909c;
  background-color: var(--mdc-theme-secondary, #78909c); }

@-webkit-keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 33, 113, 0.54);
    background-color: transparent; }
  50% {
    border-color: #78909c;
    /* @alternate */
    border-color: #78909c;
    border-color: var(--mdc-theme-secondary, #78909c);
    background-color: #78909c;
    /* @alternate */
    background-color: #78909c;
    background-color: var(--mdc-theme-secondary, #78909c); } }

@keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 33, 113, 0.54);
    background-color: transparent; }
  50% {
    border-color: #78909c;
    /* @alternate */
    border-color: #78909c;
    border-color: var(--mdc-theme-secondary, #78909c);
    background-color: #78909c;
    /* @alternate */
    background-color: #78909c;
    background-color: var(--mdc-theme-secondary, #78909c); } }

@-webkit-keyframes mdc-checkbox-fade-out-background-0 {
  0%, 80% {
    border-color: #78909c;
    /* @alternate */
    border-color: #78909c;
    border-color: var(--mdc-theme-secondary, #78909c);
    background-color: #78909c;
    /* @alternate */
    background-color: #78909c;
    background-color: var(--mdc-theme-secondary, #78909c); }
  100% {
    border-color: rgba(0, 33, 113, 0.54);
    background-color: transparent; } }

@keyframes mdc-checkbox-fade-out-background-0 {
  0%, 80% {
    border-color: #78909c;
    /* @alternate */
    border-color: #78909c;
    border-color: var(--mdc-theme-secondary, #78909c);
    background-color: #78909c;
    /* @alternate */
    background-color: #78909c;
    background-color: var(--mdc-theme-secondary, #78909c); }
  100% {
    border-color: rgba(0, 33, 113, 0.54);
    background-color: transparent; } }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  -webkit-animation-name: mdc-checkbox-fade-in-background-0;
          animation-name: mdc-checkbox-fade-in-background-0; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  -webkit-animation-name: mdc-checkbox-fade-out-background-0;
          animation-name: mdc-checkbox-fade-out-background-0; }

.mdc-checkbox__native-control:disabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 33, 113, 0.26); }

.mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:disabled:indeterminate ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 33, 113, 0.26); }

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__mixedmark {
    margin: 0 1px; } }

.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none; }

.mdc-checkbox__background {
  /* @noflip */
  left: 11px;
  /* @noflip */
  right: initial;
  display: inline-flex;
  position: absolute;
  top: 11px;
  bottom: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 45%;
  height: 45%;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color; }
  .mdc-checkbox[dir="rtl"] .mdc-checkbox__background,
  [dir="rtl"] .mdc-checkbox .mdc-checkbox__background {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 11px; }

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  opacity: 0; }
  .mdc-checkbox--upgraded .mdc-checkbox__checkmark {
    opacity: 1; }

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.78334;
  stroke-dasharray: 29.78334; }

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  -webkit-transform: scaleX(0) rotate(0deg);
          transform: scaleX(0) rotate(0deg);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  border-width: 1px;
  border-style: solid;
  opacity: 0; }

.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none !important; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  -webkit-animation-duration: 180ms;
          animation-duration: 180ms;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  -webkit-animation: 180ms linear 0s mdc-checkbox-unchecked-checked-checkmark-path;
          animation: 180ms linear 0s mdc-checkbox-unchecked-checked-checkmark-path;
  transition: none; }

.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  -webkit-animation: 90ms linear 0s mdc-checkbox-unchecked-indeterminate-mixedmark;
          animation: 90ms linear 0s mdc-checkbox-unchecked-indeterminate-mixedmark;
  transition: none; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  -webkit-animation: 90ms linear 0s mdc-checkbox-checked-unchecked-checkmark-path;
          animation: 90ms linear 0s mdc-checkbox-checked-unchecked-checkmark-path;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  -webkit-animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-checkmark;
          animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-checkmark;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  -webkit-animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-mixedmark;
          animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-mixedmark;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  -webkit-animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-checkmark;
          animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-checkmark;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  -webkit-animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-mixedmark;
          animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-mixedmark;
  transition: none; }

.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  -webkit-animation: 300ms linear 0s mdc-checkbox-indeterminate-unchecked-mixedmark;
          animation: 300ms linear 0s mdc-checkbox-indeterminate-unchecked-mixedmark;
  transition: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
    stroke-dashoffset: 0; }

.mdc-checkbox__background::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  will-change: opacity, transform; }

.mdc-ripple-upgraded--background-focused .mdc-checkbox__background::before {
  content: none; }

.mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before {
  -webkit-transform: scale(2.75, 2.75);
          transform: scale(2.75, 2.75);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 0.12; }

.mdc-checkbox__native-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit; }
  .mdc-checkbox__native-control:disabled {
    cursor: default;
    pointer-events: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  -webkit-transform: scaleX(1) rotate(-45deg);
          transform: scaleX(1) rotate(-45deg); }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  opacity: 0; }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  -webkit-transform: scaleX(1) rotate(0deg);
          transform: scaleX(1) rotate(0deg);
  opacity: 1; }

@-webkit-keyframes mdc-select-float-native-control {
  0% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes mdc-select-float-native-control {
  0% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.mdc-select {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23002171%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  overflow: hidden;
  will-change: opacity, transform, color; }
  .mdc-select:not(.mdc-select--disabled) {
    background-color: #f5f6f9; }
  .mdc-select::before, .mdc-select::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-select::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-select.mdc-ripple-upgraded::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-select.mdc-ripple-upgraded--unbounded::after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: 0;
    left: var(--mdc-ripple-left, 0); }
  .mdc-select.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-select.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select::before, .mdc-select::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-select.mdc-ripple-upgraded::after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-select::before, .mdc-select::after {
    background-color: rgba(0, 33, 113, 0.87); }
  .mdc-select:hover::before {
    opacity: 0.04; }
  .mdc-select:not(.mdc-ripple-upgraded):focus::before, .mdc-select.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
    color: rgba(0, 33, 113, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
    color: rgba(0, 33, 113, 0.6); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
    border-bottom-color: rgba(0, 33, 113, 0.42); }
  .mdc-select, .mdc-select__native-control {
    border-radius: 4px 4px 0 0; }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-line-ripple {
    background-color: #0d47a1;
    /* @alternate */
    background-color: #0d47a1;
    background-color: var(--mdc-theme-primary, #0d47a1); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-floating-label {
    color: rgba(13, 71, 161, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover {
    border-bottom-color: rgba(0, 33, 113, 0.87); }
  .mdc-select .mdc-floating-label--float-above {
    -webkit-transform: translateY(-40%) scale(0.75);
            transform: translateY(-40%) scale(0.75); }
  .mdc-select .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    bottom: 12px;
    line-height: 1.75rem;
    pointer-events: none; }
    [dir="rtl"] .mdc-select .mdc-floating-label, .mdc-select .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }
  [dir="rtl"] .mdc-select, .mdc-select[dir="rtl"] {
    background-position: left 10px center; }
  .mdc-select__native-control {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 26px;
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    width: 100%;
    height: 56px;
    padding-top: 20px;
    padding-bottom: 4px;
    border: none;
    border-bottom: 1px solid;
    outline: none;
    background-color: transparent;
    color: inherit;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
            appearance: none; }
    [dir="rtl"] .mdc-select__native-control, .mdc-select__native-control[dir="rtl"] {
      /* @noflip */
      padding-left: 26px;
      /* @noflip */
      padding-right: 16px; }
    .mdc-select__native-control::-ms-expand {
      display: none; }
    .mdc-select__native-control::-ms-value {
      background-color: transparent;
      color: inherit; }

@-moz-document url-prefix("") {
  .mdc-select__native-control {
    text-indent: -2px; } }
    .mdc-select__native-control > option {
      background-color: #ffffff;
      /* @alternate */
      background-color: #ffffff;
      background-color: var(--mdc-theme-surface, #ffffff);
      color: inherit; }
  .mdc-select .mdc-select__native-control:focus ~ .mdc-line-ripple::after {
    -webkit-transform: scale(1, 2);
            transform: scale(1, 2);
    opacity: 1; }

.mdc-select--outlined {
  border: none;
  overflow: visible; }
  .mdc-select--outlined:not(.mdc-select--disabled) {
    background-color: transparent; }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__idle {
    border-color: rgba(0, 33, 113, 0.24); }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__path {
    stroke: rgba(0, 33, 113, 0.24); }
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select__native-control:focus) .mdc-select__native-control:hover ~ .mdc-notched-outline__idle {
    border-color: rgba(0, 33, 113, 0.87); }
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select__native-control:focus) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
    stroke: rgba(0, 33, 113, 0.87); }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-notched-outline .mdc-notched-outline__path {
    stroke-width: 2px; }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-notched-outline .mdc-notched-outline__path {
    stroke: #0d47a1;
    /* @alternate */
    stroke: #0d47a1;
    stroke: var(--mdc-theme-primary, #0d47a1); }
  .mdc-select--outlined .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) scale(0.75);
            transform: translateY(-130%) scale(0.75); }
  .mdc-select--outlined .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
            animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-select--outlined .mdc-notched-outline {
    border-radius: 4px; }
  .mdc-select--outlined .mdc-notched-outline__idle {
    border-radius: 4px; }
  .mdc-select--outlined__native-control {
    border-radius: 4px; }
  .mdc-select--outlined::before, .mdc-select--outlined::after {
    content: none; }
  .mdc-select--outlined:not(.mdc-select--disabled) {
    background-color: transparent; }
  .mdc-select--outlined .mdc-select__native-control {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 26px;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    background-color: transparent;
    z-index: 1; }
    [dir="rtl"] .mdc-select--outlined .mdc-select__native-control, .mdc-select--outlined .mdc-select__native-control[dir="rtl"] {
      /* @noflip */
      padding-left: 26px;
      /* @noflip */
      padding-right: 16px; }
  .mdc-select--outlined .mdc-floating-label {
    bottom: 20px;
    line-height: 1.15rem;
    pointer-events: auto; }

.mdc-select--disabled {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23002171%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.37%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  background-color: #fafbfc;
  cursor: default;
  pointer-events: none; }
  .mdc-select--disabled .mdc-floating-label {
    color: rgba(0, 33, 113, 0.37); }
  .mdc-select--disabled .mdc-line-ripple {
    display: none; }
  .mdc-select--disabled .mdc-select__native-control {
    border-bottom-style: dotted;
    color: rgba(0, 33, 113, 0.37); }
  .mdc-select--disabled.mdc-select--outlined {
    background-color: transparent; }
    .mdc-select--disabled.mdc-select--outlined .mdc-select__native-control {
      border-bottom-style: none; }
    .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__idle {
      border-color: rgba(0, 33, 113, 0.16); }
    .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__path {
      stroke: rgba(0, 33, 113, 0.16); }

.mdc-radio {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  /* @alternate */
  will-change: opacity, transform, border-color, color; }
  .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: rgba(0, 33, 113, 0.54); }
  .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: #78909c;
    /* @alternate */
    border-color: #78909c;
    border-color: var(--mdc-theme-secondary, #78909c); }
  .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: #78909c;
    /* @alternate */
    border-color: #78909c;
    border-color: var(--mdc-theme-secondary, #78909c); }
  .mdc-radio .mdc-radio__background::before {
    background-color: #78909c; }
    @supports not (-ms-ime-align: auto) {
      .mdc-radio .mdc-radio__background::before {
        /* @alternate */
        background-color: #78909c;
        background-color: var(--mdc-theme-secondary, #78909c); } }
  .mdc-radio::before, .mdc-radio::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-radio::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-radio.mdc-ripple-upgraded::before {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-radio.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-radio.mdc-ripple-upgraded--unbounded::after {
    top: 0;
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: 0;
    left: var(--mdc-ripple-left, 0); }
  .mdc-radio.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-radio.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(0) scale(1);
            transform: translate(0) scale(1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-radio::before, .mdc-radio::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-radio.mdc-ripple-upgraded::before, .mdc-radio.mdc-ripple-upgraded::after {
    top: calc(50% - 50%);
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: calc(50% - 50%);
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-radio.mdc-ripple-upgraded::after {
    width: 100%;
    width: var(--mdc-ripple-fg-size, 100%);
    height: 100%;
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-radio::before, .mdc-radio::after {
    background-color: #78909c; }
    @supports not (-ms-ime-align: auto) {
      .mdc-radio::before, .mdc-radio::after {
        /* @alternate */
        background-color: #78909c;
        background-color: var(--mdc-theme-secondary, #78909c); } }
  .mdc-radio:hover::before {
    opacity: 0.04; }
  .mdc-radio:not(.mdc-ripple-upgraded):focus::before, .mdc-radio.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-radio:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-radio:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-radio.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-radio__background {
    display: inline-block;
    position: absolute;
    left: 10px;
    box-sizing: border-box;
    width: 50%;
    height: 50%; }
    .mdc-radio__background::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
      transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
      transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
  .mdc-radio__outer-circle {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    transition: border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    border-width: 2px;
    border-style: solid;
    border-radius: 50%; }
  .mdc-radio__inner-circle {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    transition: border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    border-width: 10px;
    border-style: solid;
    border-radius: 50%; }
  .mdc-radio__native-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: inherit;
    z-index: 1; }
  .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__background::before {
    content: none; }

.mdc-radio__native-control:checked + .mdc-radio__background,
.mdc-radio__native-control:disabled + .mdc-radio__background {
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
    transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
    transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
    transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-radio--disabled {
  cursor: default;
  pointer-events: none; }

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-radio__native-control:disabled + .mdc-radio__background,
[aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background {
  cursor: default; }
  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle,
  [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: rgba(0, 33, 113, 0.26); }
  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle,
  [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: rgba(0, 33, 113, 0.26); }

.mdc-radio__native-control:focus + .mdc-radio__background::before {
  -webkit-transform: scale(2, 2);
          transform: scale(2, 2);
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 0.12; }

.mdc-form-field {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle; }
  .mdc-form-field > label {
    order: 0;
    margin-right: auto;
    padding-left: 4px; }
  [dir="rtl"] .mdc-form-field > label, .mdc-form-field[dir="rtl"] > label {
    margin-left: auto;
    padding-right: 4px; }

.mdc-form-field--align-end > label {
  order: -1;
  margin-left: auto;
  padding-right: 4px; }

[dir="rtl"] .mdc-form-field--align-end > label, .mdc-form-field--align-end[dir="rtl"] > label {
  margin-right: auto;
  padding-left: 4px; }

.mdc-drawer {
  border-color: rgba(0, 33, 113, 0.12);
  background-color: #ffffff;
  /* @noflip */
  border-radius: 0 0 0 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-right-width: 1px;
  border-right-style: solid;
  overflow: hidden; }
  .mdc-drawer .mdc-drawer__title {
    color: rgba(0, 33, 113, 0.87); }
  .mdc-drawer .mdc-list-group__subheader {
    color: rgba(0, 33, 113, 0.6); }
  .mdc-drawer .mdc-drawer__subtitle {
    color: rgba(0, 33, 113, 0.6); }
  .mdc-drawer .mdc-list-item__graphic {
    color: rgba(0, 33, 113, 0.6); }
  .mdc-drawer .mdc-list-item {
    color: rgba(0, 33, 113, 0.87); }
  .mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
    color: #0d47a1; }
  .mdc-drawer .mdc-list-item--activated {
    color: rgba(13, 71, 161, 0.87); }
  [dir="rtl"] .mdc-drawer, .mdc-drawer[dir="rtl"] {
    /* @noflip */
    border-radius: 0 0 0 0; }
  .mdc-drawer .mdc-list-item {
    border-radius: 4px; }
  .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
    /* @noflip */
    margin-left: 256px;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 256px; }
  [dir="rtl"] .mdc-drawer, .mdc-drawer[dir="rtl"] {
    border-right-width: 0;
    border-left-width: 1px;
    border-right-style: none;
    border-left-style: solid; }
  .mdc-drawer .mdc-list-item {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 500;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit;
    height: calc(48px - 2 * 4px);
    margin: 8px 8px;
    padding: 0 8px; }
  .mdc-drawer .mdc-list-item:nth-child(1) {
    margin-top: 2px; }
  .mdc-drawer .mdc-list-item:nth-last-child(1) {
    margin-bottom: 0; }
  .mdc-drawer .mdc-list-group__subheader {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.01786em;
    text-decoration: inherit;
    text-transform: inherit;
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    margin: 0;
    padding: 0 16px; }
    .mdc-drawer .mdc-list-group__subheader::before {
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
      vertical-align: 0; }
  .mdc-drawer .mdc-list-divider {
    margin: 3px 0 4px 0; }
  .mdc-drawer .mdc-list-item__text,
  .mdc-drawer .mdc-list-item__graphic {
    pointer-events: none; }

.mdc-drawer--animate {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  [dir="rtl"] .mdc-drawer--animate, .mdc-drawer--animate[dir="rtl"] {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }

.mdc-drawer--opening {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition-duration: 250ms; }
  [dir="rtl"] .mdc-drawer--opening, .mdc-drawer--opening[dir="rtl"] {
    -webkit-transform: translateX(0);
            transform: translateX(0); }

.mdc-drawer--closing {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition-duration: 200ms; }
  [dir="rtl"] .mdc-drawer--closing, .mdc-drawer--closing[dir="rtl"] {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px; }

.mdc-drawer__title {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px; }
  .mdc-drawer__title::before {
    display: inline-block;
    width: 0;
    height: 36px;
    content: "";
    vertical-align: 0; }
  .mdc-drawer__title::after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: -20px; }

.mdc-drawer__subtitle {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0; }
  .mdc-drawer__subtitle::before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: 0; }

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.mdc-drawer--dismissible {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute; }
  [dir="rtl"] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 0; }
  .mdc-drawer--dismissible.mdc-drawer--open {
    display: flex; }

.mdc-drawer-app-content {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
  position: relative; }
  [dir="rtl"] .mdc-drawer-app-content, .mdc-drawer-app-content[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }

.mdc-drawer--modal {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: fixed; }
  .mdc-drawer--modal + .mdc-drawer-scrim {
    background-color: rgba(0, 33, 113, 0.32); }
  [dir="rtl"] .mdc-drawer--modal, .mdc-drawer--modal[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 0; }
  .mdc-drawer--modal.mdc-drawer--open {
    display: flex; }

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5; }
  .mdc-drawer--open + .mdc-drawer-scrim {
    display: block; }
  .mdc-drawer--animate + .mdc-drawer-scrim {
    opacity: 0; }
  .mdc-drawer--opening + .mdc-drawer-scrim {
    transition-duration: 250ms;
    opacity: 1; }
  .mdc-drawer--closing + .mdc-drawer-scrim {
    transition-duration: 200ms;
    opacity: 0; }

.mdc-typography--headline1 {
  margin-top: 0.4em;
  margin-bottom: 0.4em; }

.mdc-typography--headline2 {
  margin-top: 0.4em;
  margin-bottom: 0.4em; }

.mdc-typography--headline3 {
  margin-top: 0.4em;
  margin-bottom: 0.4em; }

.mdc-typography--headline4 {
  margin-top: 0.4em;
  margin-bottom: 0.4em; }

.mdc-typography--headline5 {
  margin-top: 0.4em;
  margin-bottom: 0.4em; }

.mdc-typography--headline6 {
  margin-top: 0.4em;
  margin-bottom: 0.4em; }

.mdc-drawer-app-content {
  background-color: #f7f8fc; }

.drawer-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  background-color: "#F7F8FC"; }

.drawer-app-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0; }

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  height: 100%; }

.pointer {
  cursor: pointer;
  padding: 0px, 16px, 0px, 4px;
  width: 200px; }

.break-word {
  word-break: break-word; }

.text-center {
  text-align: center; }

.text-decoration-none {
  text-decoration: none; }

.alternate {
  color: #0d47a1; }

.border-bottom-line {
  border-bottom: 1px #0d47a1 solid; }

.float-right {
  float: right; }

.form-group-margin .field-string {
  margin: 20px; }

.no-left-margin .field-string {
  margin: 20px 20px 20px 0; }

.hidden {
  display: none !important; }

.desktopHeader {
  z-index: 1;
  box-shadow: 2px 4px 12px 0px rgba(47, 66, 86, 0.1); }

.mdc-list a {
  text-decoration: none; }

.active .mdc-list-item {
  background-color: #134a9f !important; }

.active .mdc-list-item__text {
  color: #fbfbfb; }

.active .icon {
  fill: #fbfbfb !important; }

.mdc-drawer .mdc-list-item {
  margin-left: 16px;
  font-family: Inter, Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit;
  height: calc(48px - 2 * 4px);
  padding: 0 8px; }

::-webkit-scrollbar {
  width: 6px !important;
  color: #999ca3; }

::-webkit-scrollbar-track {
  background: transparent !important; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 20px !important; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important; }

.dashboardPaper.MuiPaper-rounded {
  box-shadow: none !important; }

.cancel-button {
  box-shadow: none !important;
  font-size: 18px !important;
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  font-weight: 600 !important;
  height: 36px;
  letter-spacing: 0.02em !important;
  line-height: 19px !important;
  padding: 4px 16px 6px !important;
  background-color: white !important;
  border: 2px solid #db3069 !important;
  color: #db3069 !important;
  font-size: 16px !important;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 22.4px; }

.cancel-button:hover {
  background-color: #fadce6 !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25) !important;
  -webkit-transform: scale(1.006);
          transform: scale(1.006); }

.confirm-button {
  box-shadow: none !important;
  font-size: 18px !important;
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  font-weight: 600 !important;
  height: 36px;
  letter-spacing: 0.02em !important;
  line-height: 19px !important;
  padding: 4px 16px 6px !important;
  background-color: #134a9f !important;
  color: white !important;
  font-size: 16px !important;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 22.4px; }

.confirm-button:hover {
  background-color: #103d82 !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25) !important;
  -webkit-transform: scale(1.006);
          transform: scale(1.006); }

.disable-button {
  box-shadow: none !important;
  font-size: 18px !important;
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  font-weight: 600 !important;
  height: 36px;
  letter-spacing: 0.02em !important;
  line-height: 19px !important;
  padding: 4px 16px 6px !important;
  background-color: #134a9f !important;
  color: white !important;
  opacity: 0.38; }

.objectDataTableContainer .MuiPaper-root {
  background-color: #f7f8fc;
  padding: 24px;
  flex-grow: 1; }

.objectDataTableContainer .MuiPaper-elevation4 {
  box-shadow: none !important; }

.dashboardDataTableContainer .MuiPaper-root {
  background-color: #f7f8fc; }

.dashboardDataTableContainer .MuiPaper-elevation4 {
  box-shadow: none !important; }

.icon-container:hover .menu-path {
  fill: rgba(0, 0, 0, 0.04); }

.multiBoxTable {
  margin-bottom: 30px; }

.multiBoxTable th {
  background-color: #eaf2ff;
  font-weight: bold;
  color: #000000; }

.multiBoxTableCell {
  padding: 0px !important; }

.MuiTableCell-head {
  border-right: 1px solid rgba(185, 185, 185, 0.5); }

.newProcess-button {
  box-shadow: none !important;
  font-size: 18px !important;
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  font-weight: 600 !important;
  height: 36px;
  letter-spacing: 0.02em !important;
  line-height: 19px !important;
  padding: 4px 16px 6px !important;
  white-space: nowrap;
  background-color: #db3069 !important;
  color: white !important;
  font-family: "Roboto";
  padding: 8px 16px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25.2px !important;
  box-shadow: none;
  border: none;
  border-radius: 4px !important;
  height: 46px !important;
  cursor: pointer; }

.filterDialog {
  display: flex;
  flex-direction: column;
  min-height: 470px;
  justify-content: space-between; }

.notificationButton {
  width: 46px !important;
  height: 46px !important; }

.buttonScrollContainer {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
  display: flex;
  padding-top: 16px; }

@media (max-width: 1030px) {
  .newProcess-button {
    font-size: 18px !important; } }

@media (max-width: 1024px) {
  .filterDialog {
    min-height: 40vh; }
  .filterContent {
    max-height: 140px;
    overflow-y: scroll; } }

@media (max-width: 715px) {
  .newProcess-button {
    font-size: 16px !important; } }

@media (max-width: 665px) {
  .MuiToolbar-regular {
    min-height: 40px !important; }
  .MuiToolbar-gutters {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .dashboardtab {
    display: inline-block !important;
    white-space: nowrap;
    overflow-x: scroll; }
  #tabsButton {
    margin-right: 8px; }
  .dashboardtab::-webkit-scrollbar {
    display: none; }
  ::-webkit-scrollbar {
    display: none !important; }
  .quickFilters {
    overflow-x: scroll;
    white-space: nowrap; }
  .quickFilters::-webkit-scrollbar {
    display: none; }
  .MUIDataTable::-webkit-scrollbar {
    display: none; }
  .MUIDataTableToolbar:root {
    display: flex;
    flex-direction: row;
    overflow-x: auto; } }

@media (max-height: 600px) {
  .filterDialog {
    min-height: 15vh; }
  .filterContent {
    max-height: 30px;
    overflow-y: scroll; } }

@media (max-width: 600px) {
  .notificationInfo {
    font-size: 14px !important; } }

@media (max-width: 475px) {
  .filterDialog {
    min-height: 30vh; }
  .filterContent {
    max-height: 70px;
    overflow-y: scroll; } }

@media (max-width: 400px) {
  .reportPaper {
    height: auto !important; } }

@media (max-width: 375px) {
  .newProcess-button {
    font-size: 18px !important; } }

@media (max-width: 350px) {
  .notificationButton {
    margin-right: 0px !important; } }

.selectLanguage.MuiInput-underline:before {
  border-bottom: none !important; }

.logo {
  height: 40px; }

.mdc-top-app-bar {
  background-color: #6200ee;
  /* @alternate */
  background-color: #6200ee;
  background-color: var(--mdc-theme-primary, #6200ee);
  color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4; }
  .mdc-top-app-bar .mdc-top-app-bar__action-item,
  .mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
    color: #fff;
    /* @alternate */
    color: #fff;
    color: var(--mdc-theme-on-primary, #fff); }
    .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
      background-color: #fff; }
      @supports not (-ms-ime-align: auto) {
        .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
        .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
        .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
          /* @alternate */
          background-color: #fff;
          background-color: var(--mdc-theme-on-primary, #fff); } }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before {
      opacity: 0.08; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.32; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.32; }
  .mdc-top-app-bar__row {
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 64px; }
  .mdc-top-app-bar__section {
    display: inline-flex;
    flex: 1 1 auto;
    align-items: center;
    min-width: 0;
    padding: 8px 12px;
    z-index: 1; }
    .mdc-top-app-bar__section--align-start {
      justify-content: flex-start;
      order: -1; }
    .mdc-top-app-bar__section--align-end {
      justify-content: flex-end;
      order: 1; }
  .mdc-top-app-bar__title {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @noflip */
    padding-left: 20px;
    /* @noflip */
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1; }
    [dir="rtl"] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir="rtl"] {
      /* @noflip */
      padding-left: 0;
      /* @noflip */
      padding-right: 20px; }
  .mdc-top-app-bar__action-item, .mdc-top-app-bar__navigation-icon {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    padding: 12px;
    border: none;
    outline: none;
    background-color: transparent;
    fill: currentColor;
    color: inherit;
    text-decoration: none;
    cursor: pointer; }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__navigation-icon::before {
      transition: opacity 15ms linear, background-color 15ms linear;
      z-index: 1; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
              transform: scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      top: 0;
      /* @noflip */
      left: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: center center;
              transform-origin: center center; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--unbounded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--unbounded::after {
      top: 0;
      top: var(--mdc-ripple-top, 0);
      /* @noflip */
      left: 0;
      left: var(--mdc-ripple-left, 0); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-activation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-activation::after {
      -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
              animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-deactivation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-deactivation::after {
      -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
              animation: 150ms mdc-ripple-fg-opacity-out;
      -webkit-transform: translate(0) scale(1);
              transform: translate(0) scale(1);
      -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
              transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
      top: calc(50% - 50%);
      /* @noflip */
      left: calc(50% - 50%);
      width: 100%;
      height: 100%; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      top: calc(50% - 50%);
      top: var(--mdc-ripple-top, calc(50% - 50%));
      /* @noflip */
      left: calc(50% - 50%);
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: 100%;
      width: var(--mdc-ripple-fg-size, 100%);
      height: 100%;
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      width: 100%;
      width: var(--mdc-ripple-fg-size, 100%);
      height: 100%;
      height: var(--mdc-ripple-fg-size, 100%); }

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-radius: 0 0 24px 0; }
  [dir="rtl"] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir="rtl"] {
    /* @noflip */
    border-radius: 0 0 0 24px; }

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */
  right: auto;
  /* @noflip */
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  [dir="rtl"] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir="rtl"] {
    /* @noflip */
    right: 0;
    /* @noflip */
    left: auto; }
  .mdc-top-app-bar--short .mdc-top-app-bar__row {
    height: 56px; }
  .mdc-top-app-bar--short .mdc-top-app-bar__section {
    padding: 4px; }
  .mdc-top-app-bar--short .mdc-top-app-bar__title {
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }

.mdc-top-app-bar--short-collapsed {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
    display: none; }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
    transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px; }
  .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px; }
    [dir="rtl"] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir="rtl"] {
      /* @noflip */
      padding-left: 12px;
      /* @noflip */
      padding-right: 0; }

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px; }

.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px; }

.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0; }
  [dir="rtl"] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start; }

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear; }

.mdc-top-app-bar--fixed-scrolled {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px; }
  [dir="rtl"] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 20px; }

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px; }

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px; }

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px; }

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px; }

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px; }

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px; }
  .mdc-top-app-bar__section {
    padding: 4px; }
  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1); }
    .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
      /* @noflip */
      padding-left: 0;
      /* @noflip */
      padding-right: 12px; }
      [dir="rtl"] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir="rtl"] {
        /* @noflip */
        padding-left: 12px;
        /* @noflip */
        padding-right: 0; }
  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px; }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px; } }

.jheader {
  color: #0d47a1;
  background-color: #ffffff;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87); }
  .jheader .mdc-top-app-bar__action-item,
  .jheader .mdc-top-app-bar__navigation-icon {
    color: #0d47a1; }
    .jheader .mdc-top-app-bar__action-item::before, .jheader .mdc-top-app-bar__action-item::after,
    .jheader .mdc-top-app-bar__navigation-icon::before,
    .jheader .mdc-top-app-bar__navigation-icon::after {
      background-color: #0d47a1; }
    .jheader .mdc-top-app-bar__action-item:hover::before,
    .jheader .mdc-top-app-bar__navigation-icon:hover::before {
      opacity: 0.04; }
    .jheader .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before, .jheader .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before,
    .jheader .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before,
    .jheader .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .jheader .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
    .jheader .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .jheader .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
    .jheader .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .jheader .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
    .jheader .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }

.mdc-top-app-bar__row {
  display: flex;
  flex-wrap: nowrap;
  height: 74px; }

.dashboardTitle {
  font-family: inter !important;
  color: #2f4256; }

@media (max-width: 600px) {
  .dashboardTitle {
    font-size: 20px !important;
    padding-left: 0px !important; }
  .mdc-top-app-bar__row {
    overflow-x: hidden;
    white-space: nowrap; }
  .hamburgerIcon {
    width: 30px;
    height: 30px; } }

.mdc-top-app-bar__section {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 20px 24px 20px 12px; }

@media (max-width: 500px) {
  .jheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none; }
    .jheader::-webkit-scrollbar {
      display: none; }
  .mdc-top-app-bar__row {
    display: flex;
    flex-wrap: nowrap;
    width: 100%; }
  .mdc-top-app-bar__section {
    flex-shrink: 0;
    display: flex;
    align-items: center; }
  .mdc-top-app-bar__section--align-start {
    flex-grow: 1;
    min-width: 0; }
  .mdc-top-app-bar__section--align-end {
    flex-shrink: 0;
    display: flex;
    align-items: center; } }

@media (max-width: 350px) {
  .mdc-top-app-bar__section {
    padding: 24px 24px 24px 0px; }
  .hamburger {
    padding-left: 10px; } }

.flex {
  margin-bottom: 8px; }

.display {
  display: flex; }

.errorMessage {
  color: red !important;
  margin-bottom: -1em !important; }

.successMessage {
  color: #4caf50 !important;
  margin-bottom: -1em !important; }

.field {
  width: 100% !important; }

.error-message-field-drawer-close {
  max-width: 100vw !important;
  width: 100%; }

.error-message-field-drawer-open {
  width: 100vw; }

.form {
  width: 100% !important; }

.gridWidth {
  width: 90%; }

.padding-emptyRadio {
  padding: 20px 0; }

.background-color {
  background-color: white; }

.webhookButton,
.webhookButton::before {
  border: 2px solid #134a9f;
  padding: 8px 16px;
  color: #134a9f;
  letter-spacing: 0.0200012em !important;
  background-color: transparent;
  height: 44px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin-top: 6px; }

.documentButton,
.documentButton::before {
  border: 2px solid #134a9f !important;
  padding: 8px 16px;
  color: #134a9f !important;
  height: 44px;
  letter-spacing: 0.0200012em !important;
  text-transform: capitalize !important;
  white-space: nowrap;
  display: block;
  margin-top: 6px !important; }

.documentButton:hover {
  background-color: #eaf2ff !important; }

.documentLabel {
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: unset; }

.MuiArrowCircular {
  -webkit-animation: MuiArrowSpinner-keyframes-circular-rotate 1.4s ease-in-out infinite;
          animation: MuiArrowSpinner-keyframes-circular-rotate 1.4s ease-in-out infinite; }

.multiBox {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative; }

.multiBox:hover {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25); }

.multiBoxIcon {
  position: absolute;
  bottom: -27px;
  background: #eaf2ff;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
  width: 20px !important;
  height: 20px !important; }

.multiBoxIcon:hover {
  border: 1px solid #ccdcf6; }

@-webkit-keyframes MuiArrowSpinner-keyframes-circular-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

@keyframes MuiArrowSpinner-keyframes-circular-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

.outcomeButton {
  box-shadow: none !important;
  background-color: #134a9f !important; }

.outcomeButton:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3) !important;
  background: #103d82; }

.autoFillButton:hover {
  background-color: #eaf2ff !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25) !important; }

.formulaText {
  font-weight: normal !important; }

.downloadButton {
  box-shadow: none !important;
  border: 2px solid #134a9f !important;
  height: 44px;
  white-space: normal;
  text-overflow: ellipsis !important;
  display: block;
  overflow: hidden;
  text-transform: none !important;
  letter-spacing: 0.0200012em !important;
  padding: 8px 16px;
  margin-top: 6px !important;
  color: #134a9f !important; }

.formulaSuccessMessage {
  color: #4caf50 !important;
  margin: 2px 8px !important;
  line-height: 80%;
  font-size: small; }

.downloadButton:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #eaf2ff !important; }

.downloadButtonLabel {
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap; }

.contentCopyIcon:hover {
  background-color: #eaf2ff;
  fill: #eaf2ff !important; }

.MuiSlider-root {
  width: 967% !important; }

.MuiSlider-thumb {
  width: 17px !important;
  height: 17px !important; }

.MuiSlider-rail {
  height: 8px !important;
  border-radius: 4px !important; }

.MuiSlider-track {
  height: 8px !important; }

.sutContainer {
  padding: 24px; }

.editorPrimaryButton:hover {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  background: #103d82; }

.editorSecondaryButton:hover {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  background: #fadce6; }

#rotateCircle:hover {
  fill: #eaf2ff; }

.formLoader {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100vh !important; }

/*
 Some standard sized
*/
.mt-0 {
  margin-top: 0em !important; }

.mtf-0 {
  margin-top: 0em !important; }

.mb-0 {
  margin-bottom: 0em !important; }

.mr-0 {
  margin-right: 0em !important; }

.ml-0 {
  margin-left: 0em !important; }

.mth-0 {
  margin-top: 0em; }

.mxf-0 {
  margin-left: 0em;
  margin-right: 0em; }

.myf-0 {
  margin-top: 0em;
  margin-bottom: 0em; }

.mx-0 {
  margin-right: 0em;
  margin-left: 0em; }

.my-0 {
  margin-top: 0em;
  margin-bottom: 0em; }

.pt-0 {
  padding-top: 0em; }

.pb-0 {
  padding-bottom: 0em; }

.pr-0 {
  padding-right: 0em; }

.pl-0 {
  padding-left: 0em; }

.px-0 {
  padding-right: 0em;
  padding-left: 0em; }

.py-0 {
  padding-top: 0em;
  padding-bottom: 0em; }

.mt-1 {
  margin-top: 1em !important; }

.mtf-1 {
  margin-top: 0.2em !important; }

.mb-1 {
  margin-bottom: 1em !important; }

.mr-1 {
  margin-right: 1em !important; }

.ml-1 {
  margin-left: 1em !important; }

.mth-1 {
  margin-top: 0.5em; }

.mxf-1 {
  margin-left: 0.5em;
  margin-right: 0.5em; }

.myf-1 {
  margin-top: 0.5em;
  margin-bottom: 0.5em; }

.mx-1 {
  margin-right: 1em;
  margin-left: 1em; }

.my-1 {
  margin-top: 1em;
  margin-bottom: 1em; }

.pt-1 {
  padding-top: 1em; }

.pb-1 {
  padding-bottom: 1em; }

.pr-1 {
  padding-right: 1em; }

.pl-1 {
  padding-left: 1em; }

.px-1 {
  padding-right: 1em;
  padding-left: 1em; }

.py-1 {
  padding-top: 1em;
  padding-bottom: 1em; }

.mt-2 {
  margin-top: 2em !important; }

.mtf-2 {
  margin-top: 0.4em !important; }

.mb-2 {
  margin-bottom: 2em !important; }

.mr-2 {
  margin-right: 2em !important; }

.ml-2 {
  margin-left: 2em !important; }

.mth-2 {
  margin-top: 1em; }

.mxf-2 {
  margin-left: 1em;
  margin-right: 1em; }

.myf-2 {
  margin-top: 1em;
  margin-bottom: 1em; }

.mx-2 {
  margin-right: 2em;
  margin-left: 2em; }

.my-2 {
  margin-top: 2em;
  margin-bottom: 2em; }

.pt-2 {
  padding-top: 2em; }

.pb-2 {
  padding-bottom: 2em; }

.pr-2 {
  padding-right: 2em; }

.pl-2 {
  padding-left: 2em; }

.px-2 {
  padding-right: 2em;
  padding-left: 2em; }

.py-2 {
  padding-top: 2em;
  padding-bottom: 2em; }

.mt-3 {
  margin-top: 3em !important; }

.mtf-3 {
  margin-top: 0.6em !important; }

.mb-3 {
  margin-bottom: 3em !important; }

.mr-3 {
  margin-right: 3em !important; }

.ml-3 {
  margin-left: 3em !important; }

.mth-3 {
  margin-top: 1.5em; }

.mxf-3 {
  margin-left: 1.5em;
  margin-right: 1.5em; }

.myf-3 {
  margin-top: 1.5em;
  margin-bottom: 1.5em; }

.mx-3 {
  margin-right: 3em;
  margin-left: 3em; }

.my-3 {
  margin-top: 3em;
  margin-bottom: 3em; }

.pt-3 {
  padding-top: 3em; }

.pb-3 {
  padding-bottom: 3em; }

.pr-3 {
  padding-right: 3em; }

.pl-3 {
  padding-left: 3em; }

.px-3 {
  padding-right: 3em;
  padding-left: 3em; }

.py-3 {
  padding-top: 3em;
  padding-bottom: 3em; }

.mt-4 {
  margin-top: 4em !important; }

.mtf-4 {
  margin-top: 0.8em !important; }

.mb-4 {
  margin-bottom: 4em !important; }

.mr-4 {
  margin-right: 4em !important; }

.ml-4 {
  margin-left: 4em !important; }

.mth-4 {
  margin-top: 2em; }

.mxf-4 {
  margin-left: 2em;
  margin-right: 2em; }

.myf-4 {
  margin-top: 2em;
  margin-bottom: 2em; }

.mx-4 {
  margin-right: 4em;
  margin-left: 4em; }

.my-4 {
  margin-top: 4em;
  margin-bottom: 4em; }

.pt-4 {
  padding-top: 4em; }

.pb-4 {
  padding-bottom: 4em; }

.pr-4 {
  padding-right: 4em; }

.pl-4 {
  padding-left: 4em; }

.px-4 {
  padding-right: 4em;
  padding-left: 4em; }

.py-4 {
  padding-top: 4em;
  padding-bottom: 4em; }

.mt-5 {
  margin-top: 5em !important; }

.mtf-5 {
  margin-top: 1em !important; }

.mb-5 {
  margin-bottom: 5em !important; }

.mr-5 {
  margin-right: 5em !important; }

.ml-5 {
  margin-left: 5em !important; }

.mth-5 {
  margin-top: 2.5em; }

.mxf-5 {
  margin-left: 2.5em;
  margin-right: 2.5em; }

.myf-5 {
  margin-top: 2.5em;
  margin-bottom: 2.5em; }

.mx-5 {
  margin-right: 5em;
  margin-left: 5em; }

.my-5 {
  margin-top: 5em;
  margin-bottom: 5em; }

.pt-5 {
  padding-top: 5em; }

.pb-5 {
  padding-bottom: 5em; }

.pr-5 {
  padding-right: 5em; }

.pl-5 {
  padding-left: 5em; }

.px-5 {
  padding-right: 5em;
  padding-left: 5em; }

.py-5 {
  padding-top: 5em;
  padding-bottom: 5em; }

.variableName {
  width: 20%;
  padding: 15px 0 0 20px;
  font-weight: bold;
  font-size: 16px;
  word-break: break-all; }

.Error {
  color: #a94442 !important;
  background-color: #f2dede;
  border-color: #ebccd1 !important; }

.Info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1 !important; }

.Alert {
  color: #8a6d3b;
  background-color: #fff3cd;
  border-color: #faebcc !important; }

.Success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6 !important; }

.flex {
  display: flex; }

.textWrap {
  word-break: break-word; }

.newButton {
  height: 30px;
  font-size: 12px !important;
  font-weight: 550 !important;
  margin-bottom: 8px !important; }

.newButton:hover {
  background-color: #eaf2ff !important;
  color: #134a9f !important;
  border: 1px solid #ccdcf6 !important; }

.fullWidth {
  width: 100%; }

.hover-button:hover {
  box-shadow: 0px 2px 6px #ccdcf6;
  cursor: pointer;
  -webkit-transform: scale(1.03);
          transform: scale(1.03); }

.filter-button:hover {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.54);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  -webkit-transform: scale(1.01);
          transform: scale(1.01); }

.reassign-button {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  color: #134a9f;
  display: flex; }

.reassign-button:hover {
  background-color: white;
  border: 1px solid #134a9f;
  border-radius: 6px;
  color: #134a9f;
  display: flex; }

.new-process {
  align-items: flex-start;
  border: 2px solid #eff0f3;
  border-radius: 4px;
  display: flex;
  padding: 8px 16px !important;
  width: 100%; }

.new-process:hover {
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  background-color: #eaf2ff;
  -webkit-transform: scale(1.009);
          transform: scale(1.009); }

.select-head-cell {
  background-color: #eff0f3;
  border: "none"; }

body {
  margin: 0px;
  background-color: #F7F7F7;
}
form fieldset {
  border: none;
}
