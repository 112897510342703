.variableName {
  width: 20%;
  padding: 15px 0 0 20px;
  font-weight: bold;
  font-size: 16px;
  word-break: break-all;
}

.Error {
  color: #a94442 !important;
  background-color: #f2dede;
  border-color: #ebccd1 !important;
}

.Info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1 !important;
}

.Alert {
  color: #8a6d3b;
  background-color: #fff3cd;
  border-color: #faebcc !important;
}

.Success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6 !important;
}
.flex {
  display: flex;
}
.textWrap {
  word-break: break-word;
}
