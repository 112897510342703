.Toastify__toast--success {
    background-color: #FFFFFF !important;
    color: #4caf50;
    border: 2px solid #4caf50;
    border-radius: 4px !important;
    font-size: 15px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: 500;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25) !important;
}

.Toastify__close-button {
    color: inherit !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.Toastify__toast--error {
    background-color: white !important;
    border: 2px solid #D00B0B;
    color: #D00B0B;
    border-radius: 4px !important;
    font-size: 15px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.Toastify__toast {
    min-height: 56px !important;
    margin-top: 100px !important;
    margin-bottom: -90px !important;
    padding: 16px !important;
}